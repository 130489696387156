<template>
  <div>
    <div :class="$style.cardWrapper">
      <div v-for="(contact, i) in contacts" :key="i" :class="[$style.card, {[$style.cardBordered]: bordered}]">
        <lv-icon name="auchan-logo" :size="104" :class="$style.auchanLogo" />
        <div :class="$style.iconWrapper">
          <lv-icon :name="contact.icon" :size="24" />
        </div>
        <div :class="$style.textWrapper">
          <div :class="$style.title" v-text="contact.title" />
          <a :href="contact.href" v-on="promotionClick(i)" v-text="contact.label" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { genClickEventHandlers } from '~~/common/plugins/aw-analytics.js';

  defineProps({
    contacts: {
      type: Array,
      required: true,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
  });

  const emits = defineEmits(['promotion-interaction']);

  function promotionClick (index) {
    return genClickEventHandlers(() => {
      emits('promotion-interaction', index);
    });
  }

</script>

<style module lang="scss" rel="stylesheet/scss">
.cardWrapper {
  display: grid;
  gap: 20px;
  overflow: hidden;
  grid-template-columns: auto;
  grid-template-rows: 1fr 1fr;

  @include desktop-small(min) {
    gap: 32px;
  }

  @include desktop-medium(min) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 40px;
  }

  .card {
    font-size: 14px;
    font-weight: $font-weight-bold-v2;
    position: relative;
    display: grid;
    overflow: hidden;
    align-items: center;
    padding: 24px;
    word-break: break-word;
    hyphens: auto;
    border-radius: 16px;
    background-color: $color-white;
    grid-template-columns: min-content auto;
    gap: 24px;

    &Bordered {
      border: 1px solid $color-border;
    }

    @include tablet(min) {
      font-size: 16px;
    }

    .auchanLogo {
      // Direct color code instead of a variable, because that's how SVG can handle it
      --color1: #E0574F;
      position: absolute;
      right: 24px;
      bottom: -14.13px;
      width: 104px;
      height: 104px;
      // Pointer events none is because abs positioned auchanLogo could be
      // over the hyperlink and block click events.
      pointer-events: none;
      opacity: 0.12;
      stroke-width: 0;

      @include tablet(min) {
        right: 56px;
        bottom: -13px;
      }

    }

    .textWrapper {
      display: flex;
      flex-direction: column;

      .title {
        display: inline-block;
        margin-right: 24px;
      }
    }

    .iconWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 56px;
      height: 56px;
      color: $color-white;
      border-radius: 1000px;
      background-color: $color-strawberry-500;
    }
  }
}
</style>
