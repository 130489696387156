import {
  CCP_ABOVE_FIRST_ROW,
  CCP_UNDER_FIRST_ROW,
  CCP_UNDER_PRODUCT_LIST,
  CCP_UNDER_SECOND_ROW,
  CCP_UNDER_THIRD_ROW,
} from '~~/common/config/ContentPageTypePosition.js';
import {
  SCT_EXPANDABLE_CARD_GALLERY_TEXT_ON_IMAGE,
  SCT_EXPANDABLE_CARD_GALLERY_TEXT_UNDER_IMAGE,
  SCT_FULL_ACCORDIONS,
  SCT_FULL_VIDEO_CARDS,
  SCT_LARGE_BANNER_CAROUSEL,
  SCT_WHAT_AUCHAN_PROVIDE,
} from '~~/common/config/AwStandardTypeConfig';

export function createGridElementsFromContentData (contentData) {
  const specialLayoutTypes = {
    'half|half_column_of_halves': 'half|half|half',
    'half|half_column_of_thirds': 'half|half|half|half',
    'half_column_of_halves|half': 'half|half|half',
    'half_column_of_thirds|half': 'half|half|half|half',
  };

  return contentData?.layoutBlocks?.map((layout) => {
    const layoutType = specialLayoutTypes[layout.layoutType] || layout.layoutType;
    const layoutProps = { ...layout };
    delete layoutProps.components;
    delete layoutProps.media;
    delete layoutProps.backgroundColor;
    delete layoutProps.backgroundRepeat;

    if (layoutType === 'full_carousel') {
      return {
        layoutType: layout.layoutType,
        media: layout.media,
        ...layout,
        items: [{
          id: layout.id + '-0',
          index: 0,
          widthType: 'full',
          layoutType: layout.layoutType,
          content: {
            ...layout,
            layoutTemplate: SCT_LARGE_BANNER_CAROUSEL,
            type: 'standard',
          },
        }],
      };
    }
    if (layoutType === 'full_what_auchan_provide') {
      return {
        overflowAlwaysVisible: true,
        layoutType: layout.layoutType,
        media: layout.media,
        ...layout,
        items: [{
          id: layout.id + '-0',
          index: 0,
          widthType: 'full',
          layoutType: layout.layoutType,
          content: {
            ...layout,
            layoutTemplate: SCT_WHAT_AUCHAN_PROVIDE,
            type: 'standard',
          },
        }],
      };
    }

    if (layoutType === 'full_tiles') {
      return {
        layoutType: layout.layoutType,
        media: layout.media,
        ...layout,
        items: [{
          id: layout.id + '-0',
          index: 0,
          widthType: 'full',
          layoutType: layout.layoutType,
          content: {
            ...layout,
            layoutTemplate: 'full_tiles',
            type: 'standard',
          },
        }],
      };
    }

    if (layoutType === 'full_carousel_cms_card') {
      return {
        layoutType: layout.layoutType,
        media: layout.media,
        ...layout,
        items: [{
          id: layout.id + '-0',
          index: 0,
          widthType: 'full',
          layoutType: layout.layoutType,
          content: {
            ...layout,
            layoutTemplate: 'full_carousel_cms_card',
            type: 'standard',
          },
        }],
      };
    }

    if (layoutType === 'full_openable_cards') {
      return {
        layoutType: layout.layoutType,
        media: layout.media,
        ...layout,
        items: [{
          id: layout.id + '-0',
          index: 0,
          widthType: 'full',
          layoutType: layout.layoutType,
          content: {
            ...layout,
            layoutTemplate: 'full_openable_cards',
            type: 'standard',
          },
        }],
      };
    }

    if (['full_carousel_v3_image_only', 'full_carousel_v3_text_on_image', 'full_carousel_v3_21_9', 'full_carousel_v3_16_9'].includes(layoutType)) {
      return {
        overflowVisible: true,
        layoutType: layout.layoutType,
        media: layout.media,
        ...layout,
        items: [{
          id: layout.id + '-0',
          index: 0,
          widthType: 'full',
          layoutType: layout.layoutType,
          content: {
            ...layout,
            layoutTemplate: layoutType,
            type: 'standard',
          },
        }],
      };
    }

    if (layoutType === 'full_video_cards') {
      return {
        overflowVisible: true,
        overflowAlwaysVisible: true,
        layoutType: layout.layoutType,
        media: layout.media,
        ...layout,
        items: [{
          id: layout.id + '-0',
          index: 0,
          widthType: 'full',
          layoutType: layout.layoutType,
          content: {
            ...layout,
            layoutTemplate: SCT_FULL_VIDEO_CARDS,
            type: 'standard',
          },
        }],
      };
    }

    if (['full_flow_chart_cards', 'full_flow_chart_with_image_cards'].includes(layoutType)) {
      return {
        overflowAlwaysVisible: true,
        overflowVisible: true,
        layoutType: layout.layoutType,
        media: layout.media,
        ...layout,
        items: [{
          id: layout.id + '-0',
          index: 0,
          widthType: 'full',
          layoutType: layout.layoutType,
          content: {
            ...layout,
            layoutTemplate: layoutType,
            type: 'standard',
          },
        }],
      };
    }

    if (layoutType === 'full_accordions') {
      return {
        overflowAlwaysVisible: true,
        overflowVisible: true,
        layoutType: layout.layoutType,
        media: layout.media,
        ...layout,
        items: [{
          id: layout.id + '-0',
          index: 0,
          widthType: 'full',
          layoutType: layout.layoutType,
          content: {
            ...layout,
            layoutTemplate: SCT_FULL_ACCORDIONS,
            type: 'standard',
          },
        }],
      };
    }

    if ([SCT_EXPANDABLE_CARD_GALLERY_TEXT_ON_IMAGE, SCT_EXPANDABLE_CARD_GALLERY_TEXT_UNDER_IMAGE].includes(layoutType)) {
      return {
        overflowAlwaysVisible: true,
        overflowVisible: true,
        layoutType: layout.layoutType,
        media: layout.media,
        ...layout,
        items: [{
          id: layout.id + '-0',
          index: 0,
          widthType: 'full',
          layoutType: layout.layoutType,
          content: {
            ...layout,
            layoutTemplate: layoutType,
            type: 'standard',
          },
        }],
      };
    }

    return {
      layoutType: layout.layoutType,
      media: layout.media,
      ...layout,
      items: layoutType.split('|').map((splitLayout, index) => ({
        id: layout.id + '-' + index,
        index,
        content: {
          ...layoutProps,
          ...layout?.components?.[index] || {},
          title: layout?.components?.[index]?.title || '',
        },
        layoutType: layout.layoutType,
        widthType: splitLayout,
      }),
      ),
    };
  }) || [];
}

export function getContentDataLengths (contentData) {
  return (contentData
    .map(createGridElementsFromContentData)
    .map((gridRow) => {
      return gridRow?.reduce(
        (acc, gridRow) => {
          return acc + gridRow?.items?.length || 0;
        },
        0,
      );
    })
  );
}

export function getSortedContentDataByPos (contentData, sortPositions) {
  return [...contentData].sort((a, b) => {
    return (
      sortPositions.indexOf(a.position) -
      sortPositions.indexOf(b.position)
    );
  });
}

export const contentPageTypePositionsByIndex = [
  CCP_ABOVE_FIRST_ROW,
  CCP_UNDER_FIRST_ROW,
  CCP_UNDER_SECOND_ROW,
  CCP_UNDER_THIRD_ROW,
  CCP_UNDER_PRODUCT_LIST,
];
