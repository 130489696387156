import { defineAsyncComponent } from 'vue';
import type { Cct } from '~~/common/utils/Cct.ts';

export const SCT_BANNER_WITH_TEXT_BOX = 'banner_with_text_box';
export const SCT_BANNER_WITH_TEXT_BOX_IMAGE = 'banner_with_text_box_image';
export const SCT_BAR_FOR_TEXT_AND_IMAGE = 'bar_for_text_and_image';
export const SCT_BUTTONS = 'buttons';
export const SCT_CENTERED_VIDEO = 'centered_video';
export const SCT_CMS_CARD = 'cms_card';
export const SCT_DIVIDED_TITLE_AND_TEXT = 'divided_title_and_text';
export const SCT_DIVIDED_VIDEO = 'divided_video';
export const SCT_IMAGE_AND_TEXT_BAR = 'image_and_text_bar';
export const SCT_IMAGE_TEXT_LINK_COLUMNS = 'image_text_link_columns';
export const SCT_LARGE_BANNER = 'large_banner';
export const SCT_LARGE_BANNER_CAROUSEL = 'large_banner_carousel';
export const SCT_FULL_CAROUSEL_V3_16_9 = 'full_carousel_v3_16_9';
export const SCT_FULL_CAROUSEL_V3_21_9 = 'full_carousel_v3_21_9';
export const SCT_FULL_CAROUSEL_V3_IMAGE_ONLY = 'full_carousel_v3_image_only';
export const SCT_FULL_CAROUSEL_V3_TEXT_ON_IMAGE = 'full_carousel_v3_text_on_image';
export const SCT_TEAM_MEMBER_PARTNER_CUSTOMER_REVIEW = 'team_member_partner_customer_review';
export const SCT_TEXT_BLOCK = 'text_block';
export const SCT_JS_BLOCK = 'js_block';
export const SCT_TITLE_BANNER_WITH_SUBTITLE = 'title_banner_with_subtitle';
export const SCT_PRICE_STOP = 'price_stop';
export const SCT_FULL_TILES = 'full_tiles';
export const SCT_WHAT_AUCHAN_PROVIDE = 'full_what_auchan_provide';
export const SCT_ARTICLE_LIST = 'article_list';
export const SCT_FULL_CAROUSEL_CMS_CARD = 'full_carousel_cms_card';
export const SCT_FULL_VIDEO_CARDS = 'full_video_cards';
export const SCT_FULL_OPENABLE_CARDS = 'full_openable_cards';
export const SCT_FULL_FLOW_CHART_CARDS = 'full_flow_chart_cards';
export const SCT_FULL_FLOW_CHART_WITH_IMAGE_CARDS = 'full_flow_chart_with_image_cards';
export const SCT_FULL_ACCORDIONS = 'full_accordions';
export const SCT_EXPANDABLE_CARD_GALLERY_TEXT_ON_IMAGE = 'exp_card_gallery_txt_on_img';
export const SCT_EXPANDABLE_CARD_GALLERY_TEXT_UNDER_IMAGE = 'exp_card_gallery_txt_under_img';

const config: { [key: string]: Cct } = {
  [SCT_BANNER_WITH_TEXT_BOX]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwBannerWithTextBox.vue')),
    analytics: 'banner_szovegdoboz',
  },
  [SCT_BANNER_WITH_TEXT_BOX_IMAGE]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwBannerWithTextBoxImage.vue')),
    analytics: 'banner_szovegdoboz_kep',
  },
  [SCT_BAR_FOR_TEXT_AND_IMAGE]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwBarForTextAndImage.vue')),
    analytics: 'szoveg_kep_kiemeles',
  },
  [SCT_BUTTONS]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwButtons.vue')),
    analytics: 'gombok',
  },
  [SCT_CENTERED_VIDEO]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwCenteredVideo.vue')),
    analytics: 'kozepre_video',
  },
  [SCT_CMS_CARD]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwCmsCard.vue')),
    analytics: 'cms_kartya',
  },
  [SCT_DIVIDED_TITLE_AND_TEXT]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwDividedTitleAndText.vue')),
    analytics: 'ketteosztott_cim_szovegdoboz',
  },
  [SCT_DIVIDED_VIDEO]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwDividedVideo.vue')),
    analytics: 'ketteosztott_video_szovegdoboz',
  },
  [SCT_IMAGE_AND_TEXT_BAR]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwImageAndTextBar.vue')),
    analytics: 'kep_szovegdoboz',
  },
  [SCT_IMAGE_TEXT_LINK_COLUMNS]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwImageTextLinkColumns.vue')),
    analytics: 'kep_szoveg_link_oszlopok',
  },
  [SCT_TEAM_MEMBER_PARTNER_CUSTOMER_REVIEW]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwTeamMemberPartnerCustomerReview.vue')),
    analytics: 'csapattag_partner_vasarlo_ertekeles',
  },
  [SCT_TEXT_BLOCK]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwTextBlock.vue')),
    analytics: 'szoveges_blokk',
  },
  [SCT_JS_BLOCK]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwJsBlock.vue')),
    analytics: 'js_blokk',
  },
  [SCT_TITLE_BANNER_WITH_SUBTITLE]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwTitleBannerWithSubtitle.vue')),
    analytics: 'cim_banner_alcim',
  },
  [SCT_LARGE_BANNER]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwLargeBanner.vue')),
    analytics: 'nagy_banner',
  },
  [SCT_LARGE_BANNER_CAROUSEL]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwLargeBannerCarousel.vue')),
    analytics: 'nagy_banner_carousel',
    isAnalyticsRunByStandard: false,
  },
  [SCT_PRICE_STOP]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwPriceStop.vue')),
    analytics: 'arstop',
  },
  [SCT_FULL_TILES]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwFullTiles.vue')),
    analytics: 'teljes_szelessegu_csempek',
  },
  [SCT_WHAT_AUCHAN_PROVIDE]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwWhatAuchanProvide.vue')),
    analytics: 'mit_nyujt_az_auchan',
  },
  [SCT_ARTICLE_LIST]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwArticleList.vue')),
    analytics: 'cikk_lista',
  },
  [SCT_FULL_CAROUSEL_V3_16_9]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwCarouselV3/AwCarouselV3_16_9.vue')),
    analytics: 'teljes_szelessegu_carousel_v3_16_9',
    isAnalyticsRunByStandard: false,
  },
  [SCT_FULL_CAROUSEL_V3_21_9]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwCarouselV3/AwCarouselV3_21_9.vue')),
    analytics: 'teljes_szelessegu_carousel_v3_21_9',
    isAnalyticsRunByStandard: false,
  },
  [SCT_FULL_CAROUSEL_V3_IMAGE_ONLY]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwCarouselV3/AwCarouselV3ImageOnly.vue')),
    analytics: 'teljes_szelessegu_carousel_v3_csak_keppel',
    isAnalyticsRunByStandard: false,
  },
  [SCT_FULL_CAROUSEL_V3_TEXT_ON_IMAGE]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwCarouselV3/AwCarouselV3TextOnImage.vue')),
    analytics: 'teljes_szelessegu_carousel_v3_szoveggel_a_kepen',
    isAnalyticsRunByStandard: false,
  },
  [SCT_FULL_CAROUSEL_CMS_CARD]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwFullCarouselCmsCard.vue')),
    analytics: 'teljes_szelessegu_carousel_cms_kartya',
  },
  [SCT_FULL_VIDEO_CARDS]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwFullVideoCards.vue')),
    analytics: 'teljes_video_kartyak',
  },
  [SCT_FULL_OPENABLE_CARDS]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwOpenableCards/AwOpenableCards.vue')),
    analytics: 'kinyithato_kartyak',
  },
  [SCT_FULL_FLOW_CHART_CARDS]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwFlowChart/AwFullFlowChartCards.vue')),
    analytics: 'folyamatabra_kartyak',
  },
  [SCT_FULL_FLOW_CHART_WITH_IMAGE_CARDS]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwFlowChart/AwFullFlowChartWithImageCards.vue')),
    analytics: 'kepes_folyamatabra_kartyak',
  },
  [SCT_FULL_ACCORDIONS]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwFullAccordions.vue')),
    analytics: 'teljes_accordionok',
  },
  [SCT_EXPANDABLE_CARD_GALLERY_TEXT_ON_IMAGE]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwExpandableCardGallery/AwExpandableCardGalleryTextOnImage.vue')),
    analytics: 'kinyithato_galeria_kepen_szoveggel',
  },
  [SCT_EXPANDABLE_CARD_GALLERY_TEXT_UNDER_IMAGE]: {
    component: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwExpandableCardGallery/AwExpandableCardGalleryTextUnderImage.vue')),
    analytics: 'kinyithato_galeria_kep_alatt_szoveggel',
  },
};
export default config;
