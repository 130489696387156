<template>
  <ul ref="treeGroupEl" :role="isRoot ? 'tree' : 'group'" class="ulReset">
    <template v-if="isOpened">
      <li v-for="item in tree" :key="item.uuid" role="none">
        <div v-if="item.isDir">
          <button
            type="button"
            tabindex="-1"
            class="awSrOnly"
            @click="onRight($event, item)"
            v-text="`${item.title} ${tokens.open}`"
          />
          <button
            type="button"
            tabindex="-1"
            class="awSrOnly"
            @click="onLeft($event, item)"
            v-text="`${item.title} ${tokens.close}`"
          />
        </div>
        <div :class="$style.treeGroupItem">
          <component
            :is="(!isAppleTouch || item.link.link?.startsWith('http')) && item.link?.link ? 'a' : 'div'"
            role="treeitem"
            :tabindex="virtuallyFocusedTreeItem === item ? '0' : '-1'"
            :aria-owns="item.uuid"
            :aria-expanded="item.isDir ? expandedTreeItems.has(item.uuid) : null"
            :href="item.link.link || null"
            :target="item.newTab ? '_blank' : null"
            :class="[
              $style.treeItem,
              {
                [cssClassVirtualFocus]: virtuallyFocusedTreeItem === item,
                [$style.treeItemActive]: isActive(item)
              },
            ]"
            @click="openLink(item)"
            @keydown.down.prevent="onDown"
            @keydown.up.prevent="onUp"
            @keydown.left.prevent="onLeft"
            @keydown.right.prevent="onRight"
          >
            <span :class="$style.singleLineText" :role="isAppleTouch ? 'link' : null" v-text="item.title" />
          </component>
          <span
            aria-hidden="true"
            :class="['buttonReset', $style.buttonArrow]"
            :style="{ visibility: item.isDir ? null : 'hidden' }"
            @click.stop.prevent="onToggle(item)"
          >
            <span :class="$style.backgroundElement" />
            <lv-icon name="arrow-right-16" :size="16" />
          </span>
        </div>
        <aw-tree-menu
          v-if="item.isDir"
          :id="item.uuid"
          :is-root="false"
          :tree="item.children"
          :expanded-tree-items="expandedTreeItems"
          :virtually-focused-tree-item="virtuallyFocusedTreeItem"
          :class="$style.nestedTreeMenu"
          :custom-toggle="customToggle"
          @update:expanded-tree-items="emits('update:expanded-tree-items', $event)"
          @update:virtually-focused-tree-item="emits('update:virtually-focused-tree-item', $event)"
          @navigated="emits('navigated', $event)"
        />
      </li>
    </template>
  </ul>
</template>
<script setup>
  import { ref, computed, watch, nextTick } from 'vue';
  import { useNuxtApp, navigateTo, useRouter } from 'nuxt/app';
  import { LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';

  const cssClassVirtualFocus = 'awIsVirtuallyFocused';

  const props = defineProps({
    isRoot: {
      type: Boolean,
      required: true,
    },
    expandedTreeItems: {
      type: Set,
      required: true,
    },
    virtuallyFocusedTreeItem: {
      type: Object,
      required: true,
    },
    tree: {
      type: Array,
      required: true,
    },
    customToggle: {
      type: Function,
      default: null,
    },
    isEcomPage: {
      type: Boolean,
      default: false,
    },
  });

  const emits = defineEmits([
    'update:expanded-tree-items',
    'update:virtually-focused-tree-item',
    'navigated',
  ]);

  const {
    $awt,
    $logger,
  } = useNuxtApp();
  const router = useRouter();

  const treeGroupEl = ref(null);

  const isAppleTouch = computed(() => {
    if (/iPad|iPhone|iPod/.test(navigator.platform)) {
      return true;
    } else {
      return !!(navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2 &&
        /MacIntel/.test(navigator.platform));
    }
  });

  const tokens = computed(() => ({
    // NOTE: the buttons are there for touchscreen screen reader support.
    // This is in a computed so the function is only executed once instead for
    // each render and for each element.
    open: $awt('aw.tree_menu.toggle_open'),
    close: $awt('aw.tree_menu.toggle_close'),
  }));

  const isOpened = computed(() => {
    return props.isRoot || props.expandedTreeItems.has(props.tree[0]?.parentItem?.uuid);
  });

  if (props.isRoot) {
    watch(() => props.virtuallyFocusedTreeItem, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        nextTick(() => {
          const vEl = document.querySelector(`.${cssClassVirtualFocus}`);
          if (vEl) {
            vEl.focus();
          }
        });
      }
    });
  }

  function openLink (item) {
    if (!item.link.isOuter && item.link.link) {
      event.preventDefault();
      navigateTo(item.link.link);
      emits('navigated', item);
    }
  }

  function onUp () {
    try {
      const {
        virtuallyFocusedTreeItem,
        expandedTreeItems,
      } = props;
      const prevSiblingItem = virtuallyFocusedTreeItem.prevItem;
      if (prevSiblingItem) {
        let element = prevSiblingItem;
        while (expandedTreeItems.has(element.uuid) && element.children.length) {
          element = element.children[element.children.length - 1];
        }
        if (element) {
          emits('update:virtually-focused-tree-item', element);
        }
      } else if (virtuallyFocusedTreeItem.parentItem) {
        emits('update:virtually-focused-tree-item', virtuallyFocusedTreeItem.parentItem);
      }
    } catch (err) {
      $logger.error(err);
    }
  }

  function onDown () {
    try {
      const {
        virtuallyFocusedTreeItem,
        expandedTreeItems,
      } = props;
      if (expandedTreeItems.has(virtuallyFocusedTreeItem.uuid) && virtuallyFocusedTreeItem.children.length) {
        emits('update:virtually-focused-tree-item', virtuallyFocusedTreeItem.children[0]);
      } else {
        let element = virtuallyFocusedTreeItem;
        let nextItemToFocus = element.nextItem;
        while (element?.parentItem && !element.nextItem) {
          element = element.parentItem;
          nextItemToFocus = element.nextItem;
        }
        if (nextItemToFocus) {
          emits('update:virtually-focused-tree-item', nextItemToFocus);
        }
      }
    } catch (err) {
      $logger.error(err);
    }
  }

  function onRight (_, item) {
    try {
      const {
        virtuallyFocusedTreeItem,
        expandedTreeItems,
      } = props;
      if (item && item !== virtuallyFocusedTreeItem) {
        emits('update:virtually-focused-tree-item', item);
      }
      const {
        isDir,
        uuid,
      } = item || virtuallyFocusedTreeItem;
      if (isDir && !expandedTreeItems.has(uuid)) {
        emits('update:expanded-tree-items', new Set(expandedTreeItems).add(uuid));
      }
    } catch (err) {
      $logger.error(err);
    }
  }

  function onLeft (_, item) {
    try {
      const {
        virtuallyFocusedTreeItem,
        expandedTreeItems,
      } = props;
      if (item && item !== virtuallyFocusedTreeItem) {
        emits('update:virtually-focused-tree-item', item);
      }
      const {
        isDir,
        uuid,
        parentItem,
      } = item || virtuallyFocusedTreeItem;
      if (isDir && expandedTreeItems.has(uuid)) {
        const s = new Set(expandedTreeItems);
        s.delete(uuid);
        emits('update:expanded-tree-items', s);
      } else if (parentItem) {
        emits('update:virtually-focused-tree-item', parentItem);
      }
    } catch (err) {
      $logger.error(err);
    }
  }

  function onToggle (item) {
    try {
      const {
        customToggle,
        expandedTreeItems,
      } = props;
      if (typeof customToggle === 'function') {
        customToggle(item);
        return;
      }
      emits('update:virtually-focused-tree-item', item);
      nextTick(() => {
        if (expandedTreeItems.has(item.uuid)) {
          onLeft();
        } else {
          onRight();
        }
      });
    } catch (err) {
      $logger.error(err);
    }
  }

  function isActive (item) {
    return (item.isEcomHomePage && props.isEcomPage) || (item.link.link === router.currentRoute.value.fullPath);
  }
</script>
<style module lang="scss" rel="stylesheet/scss">
%hoverEffect {
  color: $color-strawberry-500;

  &::before {
    height: 16px !important;
  }
}

.treeGroupItem {
  --awTreeMenuTransition: all 0.5s cubic-bezier(0, 0.8, 0.3, 0.88);
  font-size: 16px;
  font-weight: 900;
  line-height: 20px;
  display: flex;
  height: 48px;
  gap: 0 8px;

  @include tablet(min) {
    font-size: 18px;
    line-height: 24px;
  }
}

.treeItem {
  flex-grow: 1;

  &:hover,
  &:focus,
  &:active,
  &Active {
    @extend %hoverEffect;
  }

  &:focus {
    background: $color-strawberry-450--12;
  }

  &:active {
    background: $color-background-3;
  }

  &::before {
    display: block;
    align-self: center;
    width: 6px;
    height: 4px;
    margin: 0 12px;
    content: "";
    transition: var(--awTreeMenuTransition);
    border-radius: 8px;
    background: currentColor;
  }


  &Active {
    background: $color-strawberry-450--12;
  }
}

.treeItem,
.buttonArrow {
  display: flex;
  align-items: center;
  height: 100%;
  transition: var(--awTreeMenuTransition);
  border-radius: 12px;
}

.singleLineText {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.buttonArrow {
  justify-content: center;
  width: 48px;
  cursor: pointer;

  &:hover {
    @extend %hoverEffect;
  }

  .backgroundElement {
    --animatedBoxSize: 0;
    position: absolute;
    z-index: -1;
    width: var(--animatedBoxSize);
    height: var(--animatedBoxSize);
    transition: var(--awTreeMenuTransition);
    opacity: 0.3;
    border-radius: inherit;
    background: $color-background-3;
  }

  &:hover {
    .backgroundElement {
      --animatedBoxSize: 48px;
      opacity: 1;
      background: $color-strawberry-450--12;
    }
  }
}

.nestedTreeMenu {
  margin-left: 30px;
}

.treeItem[aria-expanded="false"] ~ .nestedTreeMenu {
  display: none;
}
</style>
