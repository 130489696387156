<template>
  <aw-vimeo-video
    v-if="videoType === 'vimeo'"
    :id="video.video"
    :title="videoTitle"
    :padding-bottom="vimeoPaddingBottom"
  />
  <lv-youtube-video
    v-else-if="videoType === 'youtube'"
    :youtube-id="video.video"
    :padding-bottom="youtubePaddingBottom"
  />
  <div
    v-else
    :class="$style.embdedVideoWrapper"
    :pad
    :style="{ paddingBottom: customPaddingBottom }"
    v-html="video.video"
  />
</template>

<script>
  import { LvYoutubeVideo } from '~~/common/components/loginet-vue-shop/index.mjs';
  import AwVimeoVideo from '~~/common/components/Common/AwVimeoVideo';

  export default {
    name: 'AwVideo',
    components: {
      LvYoutubeVideo,
      AwVimeoVideo,
    },
    props: {
      video: {
        type: Object,
        required: true,
      },
      videoTitle: {
        type: String,
        default: '',
      },
      vimeoPaddingBottom: {
        type: String,
        default: undefined,
      },
      youtubePaddingBottom: {
        type: String,
        default: undefined,
      },
      customPaddingBottom: {
        type: String,
        default: undefined,
      },
    },
    computed: {
      videoType () {
        return this.video?.videoType;
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.embdedVideoWrapper {
  overflow: auto;
  max-width: 100%;
}
</style>
