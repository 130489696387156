import { genBannerInteractionClickEventHandlers } from '~~/common/mixins/analyticsBannerInteractionMixin.js';
import { computed, inject, onBeforeUnmount, onMounted, ref } from 'vue';
import { webpFallback } from '~~/common/utils/index.js';
import { useNuxtApp, useRuntimeConfig } from 'nuxt/app';
import { useUserInterfaceStore } from '~~/common/stores/userInterface.js';
import { LvButton } from '~~/common/components/loginet-vue-shop/index.mjs';

export function createExpandableCardGallery (props) {

  const screenRange = computed(() => {
    return useUserInterfaceStore().mediaQueries;
  });

  const opened = ref(false);
  const visibleCardCount = ref(2);

  const getRowIndexById = inject('getRowIndexById');

  const isLazyImage = computed(() => {
    const limit = useRuntimeConfig().public.lazyLoadedComponentsLimit;
    if (!getRowIndexById) {
      return true;
    }
    return getRowIndexById(props.content.id) >= limit;
  });

  const visibleContents = computed(() => {
    return props.content.components.slice(0, visibleCardCount.value);

  });
  const hiddenContents = computed(() => {
    return props.content.components.slice(visibleCardCount.value);
  });

  const hiddenContentsHeight = ref(0);
  const hiddenContentsElement = ref(null);
  const visibleContentsElement = ref(null);
  const visibleContentsElementItem = ref(null);

  function toggleOpened () {
    opened.value = !opened.value;
  }

  function getContentImageSource (media) {
    return (media?.contentImage
      ? webpFallback([{
        url: media?.contentImage.url || '',
      }])
      : []
    );
  }

  function getComponentAttrs (content) {
    const { $hyperlinkComputeBindFromUrl } = useNuxtApp();

    return content.link
      ? {
        is: LvButton,
        attrs: {
          ...$hyperlinkComputeBindFromUrl(content.link),
          stretch: true,
          styling: 'link-primary-version2',
        },
        handlers: genBannerInteractionClickEventHandlers(props),
      }
      : {
        is: 'div',
        attrs: null,
        handlers: {},
      }
    ;
  }

  function calculateVisibleCardCount () {
    const width = visibleContentsElement.value.offsetWidth;
    const cardWidth = screenRange.value['tablet-min'] ? 160 : 136;
    const gap = 12;
    visibleCardCount.value = Math.floor((width + gap) / (cardWidth + gap));

    const height = visibleContentsElementItem.value?.[0].offsetHeight;
    hiddenContentsHeight.value = (height + gap) * Math.ceil(hiddenContents.value.length / visibleCardCount.value) - gap;
  }

  onMounted(() => {
    calculateVisibleCardCount();

    const { $awEvents } = useNuxtApp();
    $awEvents.addEvent('throttled-resize', calculateVisibleCardCount);

  });
  onBeforeUnmount(() => {
    const { $awEvents } = useNuxtApp();
    $awEvents.removeEvent('throttled-resize', calculateVisibleCardCount);
  });

  return {
    opened,
    visibleContents,
    hiddenContents,
    hiddenContentsHeight,
    hiddenContentsElement,
    visibleContentsElement,
    visibleContentsElementItem,
    isLazyImage,
    toggleOpened,
    getContentImageSource,
    getComponentAttrs,
  };
}
