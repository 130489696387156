<template>
  <div :class="$style.container" :style="`--hasNavigation: ${content.components.length > 1 ? 1 : 0}`">
    <div
      :class="$style.carousel"
      :style="{ height: `${height}px`}"
      @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave"
    >
      <div
        v-for="(component, index) in content.components"
        :key="component.id"
        :class="[$style.carouselImage, {[$style.carouselImageActive]: activeIndex === index, [$style.carouselImagePrev]: previousIndex === index}]"
        :style="{height: `${height}px`}"
      >
        <div
          :class="$style.carouselImageBackground"
          :style="{background: calculateBackgroundImage(component), height: `${height}px`}"
        />
        <div ref="carouselContent" :class="$style.carouselContent">
          <div :class="$style.carouselContentHeading">
            <lv-heading
              v-if="component.title"
              ref="headingRef"
              :class="[$style.carouselTitle, {[$style.carouselTitleClamped]: titleLineClamp > 0}]"
              :tag="titleAttrs.tag"
              :level="titleAttrs.level"
              version2
              :style="`--titleLineClamp: ${titleLineClamp > 0 ? titleLineClamp : 6}`"
            >
              {{ getDisplayTitleText(component.title) }}
            </lv-heading>
            <aw-text-block
              :class="[$style.carouselText, {[$style.carouselTextClamped]: textLineClamp > 0}]"
              :content="component"
              :analytics-banner-interaction="analyticsBannerInteractionForBanner"
              :style="`--textLineClamp: ${textLineClamp > 0 ? textLineClamp : 6}`"
            />
          </div>
          <aw-buttons
            v-bind="{
              isBtnInteractive (button) {
                return true;
              },
              content: component,
              showTitle: false,
              analyticsBannerInteraction: analyticsBannerInteractionForBanner,
              analyticsMeasurable,
            }"
          />
        </div>
        <div v-if="height && getContentImage(component).url" :class="$style.backgroundImage">
          <aw-buttons
            v-bind="{
              generateBtnLink (button) {
                return component.link || button.link;
              },
              isBtnInteractive (button) {
                return true;
              },
              content: {
                ...component,
                button: {
                  items: [
                    {},
                  ],
                  ...component.button,
                  alignment: 'stretch',
                },
              },
              limit: 1,
              showTitle: false,
              analyticsBannerInteraction: analyticsBannerInteractionForBanner,
              analyticsMeasurable,
              buttonAttrs: {
                styling: 'reset',
                stretch: true,
                style: {
                  height: '100%',
                  width: '100%',
                },
              },
              containerAttrs: {
                style: {
                  width: '100%',
                },
              },
              style: {
                width: '100%',
              },
            }"
          >
            <template #[buttonTemplate[index]]>
              <img
                :key="`image-${component.id}`"
                data-background-image
                :src="getContentImage(component).url || null"
                :alt="getContentImage(component).alt"
                :loading="!isLazyImage && index === 0 ? 'eager': 'lazy'"
                :style="{height: `${height}px`}"
                @error="onImageError($event, component)"
              >
            </template>
          </aw-buttons>
        </div>
      </div>
    </div>

    <aw-carousel-v3-buttons
      v-if="contents.length > 1 && heightCalculated"
      :is-paused="paused"
      :class="$style.carouselNavigation"
      aria-label-prev="aw.cms.carousel_v3.text_on_image.prev"
      aria-label-toggle="aw.cms.carousel_v3.text_on_image.toggle"
      aria-label-next="aw.cms.carousel_v3.text_on_image.next"
      @prev="prev"
      @toggle-pause="togglePause"
      @next="next"
    />
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import analyticsBannerInteractionMixin from '~~/common/mixins/analyticsBannerInteractionMixin.js';
  import {
    createAwCarouselV3,
    createAwCarouselV3BannerAnalytics,
  } from '~~/common/mixins/awCarouselV3Mixin.js';
  import { LvHeading } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { useRuntimeConfig } from 'nuxt/app';

  export default {
    name: 'AwCarouselV3TextOnImage',
    components: {
      AwButtons: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwButtons')),
      AwTextBlock: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwTextBlock')),
      AwCarouselV3Buttons: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwCarouselV3/AwCarouselV3Buttons.vue')),
      LvHeading,
    },
    mixins: [analyticsBannerInteractionMixin],
    inject: ['getRowIndexById'],
    props: {
      content: {
        type: Object,
        required: true,
      },
      textLineClamp: {
        type: Number,
        default: 0,
      },
      titleLineClamp: {
        type: Number,
        default: 0,
      },
      maxTitleLength: {
        type: Number,
        default: 0,
      },
      titleAttrs: {
        type: Object,
        default: () => {
          return {
            tag: 'h1',
            level: '1',
          };
        },
      },
    },
    setup (props) {
      const {
        paused,
        activeIndex,
        previousIndex,
        contents,
        next,
        prev,
        togglePause,
        handleMouseEnter,
        handleMouseLeave,
        onImageError,
        getContentImage,
        calculateBackgroundImage,
      } = createAwCarouselV3({
        content: computed(() => props.content),
      });
      const { analyticsBannerInteraction } = createAwCarouselV3BannerAnalytics({
        contents,
        activeIndex,
        analyticsMeasurable: computed(() => props.analyticsMeasurable),
        analyticsBannerInteraction: computed(() => props.analyticsBannerInteraction),
      });
      return {
        paused,
        activeIndex,
        previousIndex,
        contents,
        next,
        prev,
        togglePause,
        handleMouseEnter,
        handleMouseLeave,
        onImageError,
        getContentImage,
        calculateBackgroundImage,
        analyticsBannerInteractionForBanner: analyticsBannerInteraction,
      };
    },
    data () {
      return {
        height: 0,
        resizeObserver: null,
        isTitleOverflowing: false,
        heightCalculated: false,
      };
    },
    computed: {
      buttonTemplate () {
        return this.content.components.map((component) => {
          return (component?.button?.items?.length || component.link) ? 'buttonText_0' : 'content';
        });
      },
      isLazyImage () {
        const limit = useRuntimeConfig().public.lazyLoadedComponentsLimit;
        if (!this.getRowIndexById) {
          return true;
        }
        return this.getRowIndexById(this.content.id) >= limit;
      },
    },
    mounted () {
      this.resizeObserver = new ResizeObserver(() => this.onResize());
      this.calculateHeight();
      this.resizeObserver.observe(this.$refs.headingRef[0].$el);
    },
    beforeUnmount () {
      this.resizeObserver?.disconnect();
    },
    methods: {
      calculateHeight () {
        let maxHeight = 0;
        this.$refs.carouselContent?.forEach(item => {
          maxHeight = Math.max(maxHeight, item.getBoundingClientRect().height);
        });
        this.height = maxHeight;
        this.heightCalculated = true;
      },
      getDisplayTitleText (title) {
        if (this.maxTitleLength > 0 && title.length > this.maxTitleLength) {
          // Only add ... when text-overflow: ellipsis wouldn't, to ensure no more than 3 periods will be displayed
          return title?.substring(0, this.maxTitleLength > 0 ? this.maxTitleLength : title.length).trim() + (this.isTitleOverflowing ? '' : '...');
        }
        return title?.substring(0, this.maxTitleLength > 0 ? this.maxTitleLength : title.length) ?? '';
      },
      onResize () {
        this.isTitleOverflowing = this.$refs.headingRef[0].$el.clientHeight < this.$refs.headingRef[0].$el.scrollHeight;
      },
    },
  };
</script>


<style module lang="scss" rel="stylesheet/scss">
$transitionDistance: 80px;

.container {
  --awCarouselV3TextOnImageOffset: -9999px;
  position: relative;

  // PSI improvement: precalculate the height of the carousel SSR friendly.
  // By design, minimum height of the image comp is 408 px.
  min-height: calc(408px + var(--hasNavigation) * 70px);

  @include tablet(min) {
    min-height: calc(408px + var(--hasNavigation) * 70px);
  }

  @include desktop-medium(min) {
    min-height: calc(408px + var(--hasNavigation) * 90px);
  }
}

.carousel {
  position: relative;

  &Image {
    position: absolute;
    top: 0;

    display: grid;
    visibility: hidden;
    width: 100%;

    transition: opacity 0.8s, visibility 0.8s;
    opacity: 0;
    border-radius: 16px;
    background-position: center !important;
    background-size: cover !important;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;

    @include tablet(min) {
      border-radius: 24px;

      grid-template-columns: repeat(8, 1fr);
      gap: 24px;
    }
    @include desktop-small(min) {
      grid-template-columns: repeat(12, 1fr);
      gap: 32px;
    }
    @include desktop-medium(min) {
      grid-template-columns: repeat(10, 1fr);
      gap: 40px;
    }

    &Active {
      visibility: visible;
      min-height: 408px;
      opacity: 1;

      .carouselContent {
        opacity: 1;
      }

      .carouselImageBackground {
        visibility: visible;
        transform: scale(1);
        opacity: 1;
      }
    }

    &Prev:not(.carouselImageActive) {
      .carouselImageBackground {
        transform: scale(0.85);
      }


      .carouselContent {
        @include tablet(min) {
          transform: translateX(calc(var(--awCarouselV3TextOnImageOffset) + $transitionDistance));
        }
      }
    }


    &Background {
      visibility: hidden;
      transition: opacity 0.8s, visibility 0.8s, transform 0.8s;
      transform: scale(1);
      opacity: 0;
      border-radius: 16px;

      background-position: center !important;
      background-size: cover !important;

      grid-area: 1 / 1 / 2 / 3;


      @include tablet(min) {
        border-radius: 24px;
        grid-area: 1 / 1 / 2 / 9;
      }
      @include desktop-small(min) {
        grid-area: 1 / 1 / 2 / 13;
      }
      @include desktop-medium(min) {
        grid-area: 1 / 1 / 2 / 11;
      }
    }

    .backgroundImage {
      z-index: 0;
      display: flex;
      opacity: 0.01 !important;
      grid-area: 1 / 1 / -1 / -1;
    }
  }

  &Content {
    z-index: 1;

    display: grid;
    justify-content: space-between;
    height: 100%;

    padding: 40px 16px;
    transition: opacity 0.8s, transform 0.8s;

    transform: translateX(var(--awCarouselV3TextOnImageOffset));
    opacity: 0;
    color: white;
    grid-area: 1 / 1 / 2 / 3;
    gap: 20px;
    grid-template-rows: auto max-content;

    > :not(.carouselContentHeading) {
      transform: translateX(calc(-1 * var(--awCarouselV3TextOnImageOffset)));
    }

    > .carouselContentHeading > * {
      transform: translateX(calc(-1 * var(--awCarouselV3TextOnImageOffset)));
    }

    @include tablet(min) {
      padding: 48px 0 48px 48px;
      grid-area: 1 / 1 / 2 / 6;
    }

    @include desktop-small(min) {
      grid-area: 1 / 1 / 2 / 7;
    }

    @include desktop-medium(min) {
      padding: 64px 0 64px 48px;
      grid-area: 1 / 1 / 2 / 6;
    }

    &Heading {
      display: grid;
      gap: 20px;
      justify-content: space-between;
      grid-template-rows: max-content max-content;
    }
  }

  &Title {
    overflow: hidden;
    justify-self: start;

    @include desktop-small(max) {
      font-size: $heading-1-font-size-mobile-version2 !important;
      line-height: $heading-1-line-height-mobile-version2 !important;

      max-height: calc($heading-1-line-height-mobile-version2 * 3);
    }
    @include desktop-medium(min) {
      max-height: calc($heading-1-line-height-desktop-version2 * 3);
    }

    &Clamped {
      @include multiline-ellipsis(var(--titleLineClamp, 6));
      max-height: calc(32px * var(--titleLineClamp, 6));

      @include desktop-small(min) {
        max-height: calc(32px * var(--titleLineClamp, 6));
      }
    }
  }

  &Text {
    @include desktop-medium(min) {
      margin-bottom: 20px;
    }

    &Clamped {
      margin-bottom: 0;

      p {
        @include multiline-ellipsis(var(--textLineClamp, 6));
      }
    }

    p {
      font-size: 16px;
      font-weight: $font-weight-bold-v2;
      line-height: 20px;
      overflow: hidden;

      max-height: calc(20px * var(--textLineClamp, 6));
      text-align: left;

      @include desktop-small(min) {
        font-size: 18px;
        line-height: 24px;
        max-height: calc(24px * var(--textLineClamp, 6));
      }
    }
  }

  &Navigation {
    display: grid;
    width: 128px;
    margin: 20px auto;
    padding: 8px;
    border: 1px solid $color-border;
    border-radius: 12px;
    background: $color-background-4;
    grid-template-columns: repeat(3, 32px);
    gap: 8px;

    @include tablet(min) {
      margin: 20px auto 40px;
    }
    @include desktop-medium(min) {
      margin: 40px auto;
    }
  }
}
</style>
