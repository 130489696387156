import { computed } from 'vue';
import { useNuxtApp, navigateTo } from 'nuxt/app';
import { getLocaleCodeForUrl } from '~~/common/utils/localeCode';
import { useConfigStore } from '~~/common/stores/config';
import { useTranslationStore } from '~~/common/stores/translation';

let availableLocalesPrivate;

const locale = computed(() => {
  return useNuxtApp().$i18n.locale.value;
});

export const localeCodeToUrl = computed(() => {
  const { $i18n } = useNuxtApp();
  return `/${getLocaleCodeForUrl({ $i18n })}`.replace(/\/$/, '');
});

export default {
  computed: {
    locale () {
      return locale.value;
    },
    availableLocales () {
      if (!availableLocalesPrivate) {
        availableLocalesPrivate = this.$i18n.locales.map(l => ({
          text: l.code.toUpperCase(),
          lowerCaseText: l.code.toLowerCase(),
          upperCaseText: l.code.toUpperCase(),
        }));
      }
      return availableLocalesPrivate;
    },
    localeCodeToUrl () {
      return localeCodeToUrl.value;
    },
  },
  methods: {
    async changeLanguage (item) {
      const configStore = useConfigStore();
      const translationStore = useTranslationStore();
      const localeCode = item.text.toLowerCase();
      await translationStore.fetchTranslations({ localeCode }).catch((e) => {
        this.$logger.error(e);
      });
      navigateTo(this.$i18n.setLocale(localeCode)).then(() => {
        setTimeout(() => {
          configStore.fetchConfigCommon();
        }, 100);
      }).catch(() => {
        setTimeout(() => {
          configStore.fetchConfigCommon();
        }, 100);
      });
    },
    getLocaleCodeByUrl (url) {
      const localeCodeFromUrl = url
        .split('/')
        .find(currentItem => currentItem.length)
      ;
      return this.$i18n.locales.some(({ code }) => code === localeCodeFromUrl)
        ? localeCodeFromUrl
        : this.$i18n.defaultLocale
      ;
    },
  },
};
