import { defineStore } from 'pinia';
import { useAsyncData, useNuxtApp } from 'nuxt/app';
import { uuid4 } from '~~/common/utils';
import { setErrorNotification } from '~~/common/utils/helper';
import otqs from '~~/common/utils/objectToQueryString';
import { useUserStore } from '~~/common/stores/user';
import { useConfigStore } from '~~/common/stores/config';
import {
  F_CATEGORY,
  F_EDUCATION_LEVEL,
  F_EMPLOYING_COMPANY,
  F_EMPLOYMENT_TIME,
  F_EMPLOYMENT_TYPE,
  F_IN_OUT_SIDE,
  F_JOB,
  F_JOB_NAME_SEARCH,
  F_LOCATION,
} from '~~/common/config/CareerFilter.js';
import { useAuthenticationStore } from '~~/common/stores/authentication.js';
import { ref } from 'vue';

export const IS_CAREER_FILTER_EMPTY = 'isEmptySelection';
export const CAREER_FILTER_EDUCATIONLEVEL = 'EducationLevel';
export const CAREER_FILTER_EMPLOYMENTTIME = 'EmploymentTime';
export const CAREER_FILTER_EMPLOYMENTTYPE = 'EmploymentType';
export const CAREER_FILTER_CATEGORY = 'Category';

const CAREER_CV_REQUIRED = 'career_cv_required';

export const useCareerStore = defineStore('career', () => {
  const nuxtApp = useNuxtApp();
  const filters = ref(null);
  const authenticationStore = useAuthenticationStore();

  function isCvRequired () {
    const config = useConfigStore();
    return config?.configCommon?.persistentConfig[CAREER_CV_REQUIRED];
  }

  async function fetchCareerOffer ({ offerId } = {}) {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      return await $api.$get(`/career/offers/${offerId}`);
    } catch (error) {
      if (error?.response?.status !== 404) {
        $logger.error(error);
        setErrorNotification({ nuxtApp, error });
      }
      return error?.response?.status || null;
    }
  }

  async function respondToCareerOffer ({
    isAccepted,
    offerId,
    reason = '',
  } = {}) {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      const intOfferId = parseInt(offerId, 10);
      if (!intOfferId || typeof reason !== 'string' || typeof isAccepted !== 'boolean') {
        throw new TypeError(`incorrect arguments ${offerId},${reason},${isAccepted}`);
      }
      await $api.$post('/career/offer_respond', {
        offerId: intOfferId,
        action: isAccepted ? 'accept' : 'decline',
        reason,
      });
      return [true, null];
    } catch (error) {
      $logger.error(error);
      setErrorNotification({ nuxtApp, error });
      return [false, error];
    }
  }

  function generateEmptyCareerAdvertisements (itemCount = 0) {
    return {
      itemCount,
      pageCount: 1,
      currentPage: 1,
      result: new Array(itemCount).fill().map(() => ({
        isPlaceholder: true,
        advertisementId: uuid4(),
      })),
    };
  }

  function createCareerAdvertisementsQuery ({
    page,
    itemsPerPage,
    filterGroups,
  } = {}) {
    return `/career/advertisements${otqs({
      page,
      itemsPerPage,
      ...createOrderedGroupFilter({ filterGroups }),
    })}`;
  }

  async function fetchCareerAdvertisements (queryObj) {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {

      await authenticationStore.reInitToken();

      let result = await $api.$get(queryObj);
      let data;
      await fetchCareerAdvertisementFilters();
      if (Array.isArray(result.items)) {
        data = await Promise.all(result.items.map(async (advertisement) => {
          return {
            ...advertisement,
            category: (await Promise.all(advertisement.category
              .map(async (category) => (await fetchCareerAdvertisementFiltersByNameAndValue(CAREER_FILTER_CATEGORY, category))?.name)))
              ?.join(),
            employmentType: (await Promise.all(advertisement.employmentType
              .map(async (employmentType) => (await fetchCareerAdvertisementFiltersByNameAndValue(CAREER_FILTER_EMPLOYMENTTYPE, employmentType))?.name)))
              ?.join(),
            employmentTime: (await Promise.all(advertisement.employmentTime
              .map(async (employmentTime) => (await fetchCareerAdvertisementFiltersByNameAndValue(CAREER_FILTER_EMPLOYMENTTIME, employmentTime))?.name)))
              ?.join(),
          };
        }));
        return {
          itemCount: result.count,
          pageCount: 1,
          currentPage: 1,
          result: data,
        };
      } else {
        throw new TypeError('Invalid /career/advertisements received on API.');
      }
    } catch (error) {
      $logger.error(error);
      setErrorNotification({ nuxtApp, error });
      return generateEmptyCareerAdvertisements(0);
    }
  }

  async function fetchCareerAdvertisementFilters () {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      await authenticationStore.reInitToken();

      if (!filters.value) {
        filters.value = (await $api.$get('/career/filters')).map(({
          name,
          values,
        }) => ({
          name,
          values,
        }));
      }

      return filters.value;
    } catch (error) {
      $logger.error(error);
      setErrorNotification({ nuxtApp, error });
      return [];
    }
  }

  async function fetchCareerAdvertisementById (id) {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      await authenticationStore.reInitToken();

      return [await $api.$get(`/career/advertisement/${id}`), null];
    } catch (error) {
      if (error?.response?.status !== 404) {
        $logger.error(error);
      }
      setErrorNotification({ nuxtApp, error });
      return [[], error];
    }
  }

  async function fetchCareerAdvertisementFiltersByName (filterName) {
    const { $logger } = nuxtApp;

    try {
      const filters = (await fetchCareerAdvertisementFilters())?.find(filter => filter.name === filterName)?.values;

      if (!filters) {
        return [];
      }

      return Object.entries(filters)?.map(([, filter]) => {

        return {
          id: parseInt(filter.id),
          name: filter.value,
        };
      });
    } catch (err) {
      $logger.error(err);
      return [];
    }
  }

  async function fetchCareerAdvertisementFiltersByNameAndValue (filterName, id) {
    const { $logger } = nuxtApp;

    try {
      return (await fetchCareerAdvertisementFiltersByName(filterName))?.find(filter => filter.id === id) ?? [];
    } catch (err) {
      $logger.error(err);
      return [];
    }
  }

  async function deleteCareerAccount () {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      return await $api.$delete('/career/user');
    } catch (error) {
      $logger.error(error);
      setErrorNotification({ nuxtApp, error });
    }

  }

  async function fetchCareerAdvertisementFiltersSelectForm () {
    const {
      $logger,
      $awt,
    } = nuxtApp;
    try {
      return useAsyncData(
        careerFiltersKey.value,
        async () => {
          try {
            const filterNames = {
              [F_JOB_NAME_SEARCH]: $awt('aw.career_list.filter.job_name_search.label'),
              [F_CATEGORY]: $awt('aw.career_list.filter.category.label'),
              [F_LOCATION]: $awt('aw.career_list.filter.location.label'),
              [F_JOB]: $awt('aw.career_list.filter.job.label'),
              [F_IN_OUT_SIDE]: $awt('aw.career_list.filter.in_out_side.label'),
              [F_EDUCATION_LEVEL]: $awt('aw.career_list.filter.education_level.label'),
              [F_EMPLOYING_COMPANY]: $awt('aw.career_list.filter.company.label'),
              [F_EMPLOYMENT_TIME]: $awt('aw.career_list.filter.employment_time.label'),
              [F_EMPLOYMENT_TYPE]: $awt('aw.career_list.filter.employment_type.label'),
            };
            return Object.fromEntries((await fetchCareerAdvertisementFilters()).map((filter) => ([
              filter.name,
              {
                id: filter.name,
                name: filterNames[filter.name] || '',
                values: Object.fromEntries([
                  [
                    0,
                    {
                      id: 0,
                      name: filterNames[filter.name],
                      title: true,
                      [IS_CAREER_FILTER_EMPTY]: true,
                    },
                  ],
                  // WARNING backend sometimes sends filter.values as an
                  // array and sometimes as an objects and sometimes as an
                  // empty string, so Object.entries ensures that we always
                  // iterate on an array with map.
                  ...(Object.entries(filter.values)
                    .map(([, {
                      id,
                      value,
                    }]) => {
                      return [id, {
                        id,
                        name: value,
                      }];
                    })
                  ),
                ]),
              },
            ])));
          } catch (err) {
            $logger.error(err);
            return {};
          }
        },
        {
          watch: [careerFiltersKey],
        },
      );
    } catch (err) {
      $logger.error(err);
      return {};
    }
  }

  async function createApplication (data) {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      await $api.$post('career/applications', data);
    } catch (error) {
      $logger.error(error);
      setErrorNotification({ nuxtApp, error });
    }
  }

  async function patchCareerUser (data) {
    const {
      $api,
      $logger,
    } = nuxtApp;

    try {
      return await $api.$patch(
        'career/user',
        data,
        {
          headers: {
            'Content-Type': 'application/merge-patch+json',
          },
        },
      );
    } catch (error) {
      $logger.error(error);
      setErrorNotification({ nuxtApp, error });
    }
  }

  async function postViewRecommendation (hash) {
    const {
      $api,
      $logger,
    } = nuxtApp;
    try {
      await $api.$post(`career/view_recommendation/${hash}`, {});
    } catch (error) {
      $logger.error(error);
      setErrorNotification({ nuxtApp, error });
    }
  }

  const careerFiltersKey = computed(() => {
    const email = useUserStore().data?.personalInfo?.email || '';
    return `common/stores/career_${email}`;
  });

  return {
    respondToCareerOffer,
    isCvRequired,
    fetchCareerOffer,
    generateEmptyCareerAdvertisements,
    createCareerAdvertisementsQuery,
    createOrderedGroupFilterReverse,
    fetchCareerAdvertisements,
    fetchCareerAdvertisementFiltersSelectForm,
    careerFiltersKey,
    fetchCareerAdvertisementFilters,
    fetchCareerAdvertisementById,
    deleteCareerAccount,
    fetchCareerAdvertisementFiltersByName,
    fetchCareerAdvertisementFiltersByNameAndValue,
    createApplication,
    patchCareerUser,
    postViewRecommendation,
    pushCareerCv,
  };
});

function createOrderedGroupFilter ({ filterGroups }) {
  // const filterGroups = {'kitten': [false], 'witch': [null], animal: ['dog', null, 'cat'], tree: [null, 'apple']};
  const filterKeyValues = (filterGroups && typeof filterGroups === 'object') ? filterGroups : {};
  return Object.keys(filterKeyValues).sort().reduce(
    (acc, curr) => {
      const filtered = (
        (Array.isArray(filterKeyValues[curr])
          ? filterKeyValues[curr]
          : []
        )
          .filter(a => a !== null)
      );
      if (filtered.length) {
        acc.filters.push(...new Array(filtered.length).fill(curr));
        acc.filterValues.push(...filtered);
      }
      return acc;
    },
    {
      filters: [],
      filterValues: [],
    },
  );
}

function createOrderedGroupFilterReverse ({
  filters,
  filterValues,
} = {}) {
  if (!Array.isArray(filters) || !Array.isArray(filterValues)) {
    return {};
  }
  return filters.reduce((acc, curr, idx) => {
    if (!acc[curr]) {
      acc[curr] = [];
    }
    acc[curr].push(filterValues[idx]);
    return acc;
  }, {});
}

async function pushCareerCv ({ file, cvUploadHash } = {}) {
  const { $api, $logger } = useNuxtApp();
  try {
    const formData = new FormData();
    if (cvUploadHash) {
      formData.append('cvUploadHash', cvUploadHash);
    }
    if (file) {
      formData.append('file', file);
    }
    await $api.$post('/career/cv', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return [true, {}];
  } catch (err) {
    $logger.error(err);
    return [false, { error: err }];
  }
}
