<template>
  <div :tabindex="isPlaceholder ? -1 : 0" :class="$style.careerCard" @click="onNavigateToDetails">
    <lv-heading
      :id="headingId || null"
      :tag="headingTag"
      level="4"
      version2
      :class="$style.heading"
    >
      <span :class="$style.headingText">
        {{ career.marketingName }}
      </span>
    </lv-heading>
    <div :class="$style.insider">
      <template v-if="isInsiderJob">
        <lv-icon name="auchan-logo" :size="16" :class="$style.logo" />
        <span v-text="$awt('aw.career_card.insider_job')" />
      </template>
    </div>
    <aw-svg-border is-horizontal :class="$style.border" />
    <div :class="$style.type">
      <span
        :title="career.category"
        :class="$style.typeText"
        v-text="career.category || '\xA0'"
      />
    </div>
    <div :class="$style.flags">
      <div :class="$style.flagItems">
        <client-only>
          <aw-product-flag-items
            v-for="f in flags"
            :key="f.flag"
            :product-data="{}"
            :flag-arr="[f]"
            :is-small="screenRange['mobile-max']"
            :flag-bg="$style.flagBg"
            one-line
          />
        </client-only>
      </div>
    </div>
    <div :class="$style.button">
      <lv-button
        :aria-hidden="isPlaceholder || null"
        :tabindex="isPlaceholder ? -1 : 0"
        shrink
        styling="small-primary-dark"
        :href="careerHyperlink"
        @click.prevent.stop="onNavigateToDetails"
      >
        <span :class="$style.buttonText">
          <span :class="$style.buttonTextGroup">
            <span v-text="$awt('aw.career_card.details_btn')" />
            <lv-icon name="arrow-right-24" :size="16" :class="$style.buttonTextIcon1" />
          </span>
          <lv-icon name="arrow-right-24" :size="16" :class="$style.buttonTextIcon0" />
        </span>
      </lv-button>
    </div>
  </div>
</template>

<script setup>
  import { computed, defineAsyncComponent, useCssModule } from 'vue';
  import { useNuxtApp, navigateTo } from 'nuxt/app';
  import { getCareerUrl } from '~~/common/mixins/urlMixin.js';
  import { LvButton, LvHeading, LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';
  import AwSvgBorder from '~~/common/components/AwSvgBorder.vue';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface.js';

  const AwProductFlagItems = defineAsyncComponent(() => import('~~/common/components/Page/Product/AwProductFlagItems.vue'));

  const props = defineProps({
    career: {
      type: Object,
      required: true,
    },
    headingTag: {
      type: String,
      required: true,
    },
    headingId: {
      type: String,
      default: '',
    },
  });

  const $style = useCssModule();
  const { $awt, $styleVariables } = useNuxtApp();

  const screenRange = computed(() => {
    return useUserInterfaceStore().mediaQueries;
  });

  const isPlaceholder = computed(() => {
    return props.career?.isPlaceholder || false;
  });

  const isInsiderJob = computed(() => {
    return props.career?.insideJob && !props.career?.outsideJob;
  });

  const flags = computed(() => {
    if (!props.career || typeof props.career !== 'object') {
      return [];
    }
    const {
      location: cLocation,
      employmentType: employmentTerm,
      employmentTime: employmentForm,
    } = props.career;

    return [
      {
        flag: 0,
        awBadgeLongText: cLocation,
        name: cLocation,
        groupBackgroundColor: $styleVariables.colorStrawberry450,
        iconBindings: {
          name: 'location-16',
          size: 16,
        },
      },
      {
        flag: 1,
        awBadgeLongText: employmentTerm,
        name: employmentTerm,
        groupBackgroundColor: $styleVariables.colorMarigold300,
        iconBindings: {
          name: 'time-clock-circle-25',
          size: 16,
        },
      },
      {
        flag: 2,
        awBadgeLongText: employmentForm,
        name: employmentForm,
        groupBackgroundColor: $styleVariables.colorPlum500,
        iconBindings: {
          name: 'calendar-version2-16',
          size: 16,
        },
      },
    ].filter(filter => filter.name);
  });

  const careerHyperlink = computed(() => (props.career?.isPlaceholder
    ? ''
    : getCareerUrl({ career: props.career })
  ));

  function onNavigateToDetails () {
    navigateTo(careerHyperlink.value);
  }
</script>

<style module lang="scss" rel="stylesheet/scss">
@mixin lineClamp() {
  line-height: var(--lineHeight);
  display: -webkit-box;
  overflow: hidden;
  min-height: calc(var(--lineNumber) * var(--lineHeight));
  text-overflow: ellipsis;
  -webkit-line-clamp: var(--lineNumber);
  -webkit-box-orient: vertical;
}

.careerCard {
  --sidePadding: 16px;
  display: grid;
  overflow: hidden;
  padding: 0 var(--sidePadding) 20px;
  cursor: pointer;
  border-radius: 16px;
  outline: 1px solid $color-border-disabled;
  background: $color-white;
  grid-template-columns: 1fr auto;
  grid-template-areas:
  "type type"
  "heading insider"
  "border border"
  "flags flags"
  "button button";
  gap: 0 8px;

  @include tablet(min) {
    --sidePadding: 24px;
    padding-bottom: 24px;
    border-radius: 24px;
  }
}

.heading {
  grid-area: heading;
}

.headingText {
  line-height: 20px;

  @include tablet(min) {
    --lineNumber: 3;
    --lineHeight: 24px;
    @include lineClamp();
  }
}

.logo {
  stroke: none;
}

.insider {
  grid-area: insider;
  align-self: start;
}

.type {
  --r: 16px;
  --rp1: calc(var(--r) + 1px);
  --rm100: calc(100% - var(--r));
  margin: 0 calc(-1 * var(--sidePadding));
  padding: 8px 0 calc(var(--r) + 8px);
  text-align: center;
  letter-spacing: 0.24em;
  text-transform: uppercase;
  background: $color-background-3;
  background-image: radial-gradient(circle at var(--r) 100%, white var(--r), transparent var(--rp1)), radial-gradient(circle at var(--rm100) 100%, white var(--r), transparent var(--rp1));
  grid-area: type;
  clip-path: polygon(0 0, 0 100%, var(--r) 100%, var(--r) var(--rm100), var(--rm100) var(--rm100), var(--rm100) 100%, 100% 100%, 100% 0);
  @include font(500, 10px, 16px);
  @include tablet(min) {
    --r: 24px;
  }

  &Text {
    --lineHeight: 16px;
    --lineNumber: 1;
    @include lineClamp();
    height: 16px;
    padding: 0 8px;
  }
}

.border {
  grid-area: border;
  margin: 11px 0;

  @include tablet(min) {
    margin: 19px 0 23px;
  }
}

.flags {
  grid-area: flags;
}

.button {
  grid-area: button;
  align-self: end;
  padding-top: 12px;

  @include tablet(min) {
    padding-top: 24px;
  }
}

.insider:not(:empty) {
  @include font(500, 10px, 16px);
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  padding: 7.25px 8.5px 4px;
  color: $color-strawberry-500;
  border-radius: 8px;
  background: change-color($color-strawberry-450, $alpha: 0.12);
  gap: 3.25px;

  @include tablet(min) {
    @include font(null, 12px, null);
  }
}

.buttonText {
  display: flex;
  align-items: center;
  gap: 8px;
}

.flagItems {
  display: flex;
  align-items: start;
  flex-wrap: wrap;
  gap: 5px;

  @include tablet(min) {
    flex-direction: column;
    gap: 4px;
  }
}

.buttonTextGroup {
  position: relative;
}

.buttonTextIcon1 {
  position: absolute;
  translate: 8px;
  opacity: 0;
}

.buttonTextIcon0 {
  visibility: hidden;
  width: 0;
  margin-left: -8px;
}

.careerCard,
.heading,
.buttonTextIcon0,
.buttonTextIcon1 {
  transition: all 0.32s ease-in-out;
}

.careerCard {
  &:hover:not(:focus-within) {
    box-shadow: 0 2px 8px 0 change-color($color-olive-750, $alpha: 0.08);

    .heading {
      translate: 8px;
    }
  }

  &:focus-visible {
    outline-style: double;
  }

  &:hover,
  &:focus-within {
    outline-color: currentColor;

    .buttonTextIcon0 {
      width: 16px;
      margin-left: 0;
    }

    .buttonTextIcon1 {
      opacity: 1;
    }
  }

  &:focus-within {
    outline-width: 2px;
  }
}

:export {
  flagBg: change-color($color-background-1, $alpha: 0.85);
}
</style>
