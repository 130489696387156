<template>
  <div>
    <div :class="$style.tabs" role="tablist">
      <aw-red-tab
        v-for="tab in tabs"
        :id="`red-tab-${tab.contentId}`"
        :key="tab.name"
        :tab="tab"
        :selected="tab.contentId === currentSelectedTab"
        role="tab"
        :aria-selected="(tab.contentId === currentSelectedTab).toString()"
        :aria-controls="`red-tab-content-${tab.contentId}`"
        @select="selectTab"
      />
    </div>
    <div :class="[$style.content, $style[`content${currentTabIndex}`]]" :style="{'--awRedTabsTabNumber': tabs.length}">
      <Transition
        tag="div"
        mode="out-in"
        :name="$style.contentTransition"
        :enter-active-class="$style.contentTransitionEnterActive"
        :enter-from-class="$style.contentTransitionEnter"
        :leave-active-class="$style.contentTransitionLeaveActive"
        :leave-to-class="$style.contentTransitionLeaveTo"
      >
        <div
          :id="`red-tab-content-${currentSelectedTab}`"
          :key="currentSelectedTab"
          role="tabpanel"
          :aria-labelledby="`red-tab-${currentSelectedTab}`"
          :class="$style.contentText"
        >
          <slot :content="currentTabContent" />
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
  import AwRedTab from './AwRedTab.vue';
  import { computed, ref } from 'vue';

  export default {
    name: 'AwRedTabs',
    components: {
      AwRedTab,
    },
    props: {
      tabs: {
        type: Array,
        required: true,
      },
    },
    setup (props) {

      const currentSelectedTab = ref(null);
      const currentTabIndex = computed(() => {
        return props.tabs?.findIndex((tab) => tab.contentId === currentSelectedTab.value);
      });
      const currentTabContent = computed(() => {
        return props?.tabs?.[currentTabIndex.value]?.content;
      });
      const selectTab = (contentId) => {
        currentSelectedTab.value = contentId;
      };

      return {
        currentSelectedTab,
        currentTabIndex,
        currentTabContent,
        selectTab,
      };
    },
    mounted () {
      this.currentSelectedTab = this.tabs?.[0]?.contentId;
    },
  };

</script>

<style module lang="scss" rel="stylesheet/scss">
.tabs {
  display: flex;
  flex-direction: row;
  min-height: 100px;
  margin-top: 16px;
  margin-bottom: 36px;
  gap: 4px;
  animation: fadeUp $animation-speed-medium ease-in-out;

  @include tablet (min) {
    gap: 20px;
  }
  @include desktop-small(min) {
    margin-bottom: 56px;
    gap: 40px;
  }
}

.content {
  position: relative;
  padding: 20px 16px;
  animation: fadeDown $animation-speed-medium ease-in-out;
  border-radius: 16px;
  background-color: $color-background-2;
  @include  tablet(min) {
    padding: 40px;
    border-radius: 24px;
  }

  &Transition {
    &EnterActive,
    &LeaveActive {
      transition: opacity $animation-speed-fast ease-in-out;
      opacity: 1;
    }

    &Enter,
    &LeaveTo {
      opacity: 0;
    }
  }

  &::before{
    position:absolute;
    top: -16px;
    display: inline-block;
    content:"";
    transition: all $animation-speed-fast $animation-timing-function;
    transform: scale(-1);
    border-width: 16px 12px 0;
    border-style: solid;
    border-color: $color-background-2 transparent ;
  }
  $aw-red-tab-gap-mobile: 4px;
  $aw-red-tab-gap-tablet: 20px;
  $aw-red-tab-gap-desktop: 40px;

  $aw-red-tab-gap-number: calc(var(--awRedTabsTabNumber) - 1);

  $aw-red-tab-width-mobile: calc((100% - $aw-red-tab-gap-mobile * $aw-red-tab-gap-number ) / var(--awRedTabsTabNumber));
  $aw-red-tab-width-tablet: calc((100% - $aw-red-tab-gap-tablet * $aw-red-tab-gap-number) / var(--awRedTabsTabNumber));
  $aw-red-tab-width-desktop: calc((100% - $aw-red-tab-gap-desktop * $aw-red-tab-gap-number) / var(--awRedTabsTabNumber));

  $aw-red-tab-pointer-size: 8px;

  &0::before {
    left: calc(($aw-red-tab-width-mobile - $aw-red-tab-pointer-size) / 2);
    @include desktop-small (min) {
      left: calc(($aw-red-tab-width-desktop - $aw-red-tab-pointer-size) / 2);
    }
  }

  &1::before {
    left: calc($aw-red-tab-width-mobile * 1.5 + $aw-red-tab-gap-mobile - $aw-red-tab-pointer-size);
    @include tablet (min) {
      left: calc($aw-red-tab-width-tablet * 1.5 + $aw-red-tab-gap-tablet - $aw-red-tab-pointer-size);
    }
    @include desktop-small (min) {
      left: calc($aw-red-tab-width-desktop * 1.5 + $aw-red-tab-gap-desktop - $aw-red-tab-pointer-size);
    }
  }

  &2::before {
    left: calc($aw-red-tab-width-mobile * 2.5 + $aw-red-tab-gap-mobile * 2 - $aw-red-tab-pointer-size);
    @include tablet (min) {
      left: calc($aw-red-tab-width-tablet * 2.5 + $aw-red-tab-gap-tablet * 2 - $aw-red-tab-pointer-size);
    }
    @include desktop-small (min) {
      left: calc($aw-red-tab-width-desktop * 2.5 + $aw-red-tab-gap-desktop * 2 - $aw-red-tab-pointer-size);
    }
  }

  &3::before {
    left: calc($aw-red-tab-width-mobile * 3.5 + $aw-red-tab-gap-mobile * 3 - $aw-red-tab-pointer-size);
    @include tablet (min) {
      left: calc($aw-red-tab-width-tablet * 3.5 + $aw-red-tab-gap-tablet * 3 - $aw-red-tab-pointer-size);
    }
    @include desktop-small (min) {
      left: calc($aw-red-tab-width-desktop * 3.5 + $aw-red-tab-gap-desktop * 3 - $aw-red-tab-pointer-size);
    }
  }
}

@keyframes fadeUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
};
@keyframes fadeDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
};
</style>
