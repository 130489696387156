import slugify from 'slugify';
import { useNuxtApp } from 'nuxt/app';
import languageDataMixin, { localeCodeToUrl } from './languageDataMixin';
import { URL_PREFIX } from '~/awPlugins/app-config';
import { findCategoryById } from '~~/common/utils/helper';
import otqs from '~~/common/utils/objectToQueryString';
import { useCategoryStore } from '~~/common/stores/category';
import { useCompareStore } from '~~/common/stores/compare';
import { useProductsStore } from '~~/common/stores/products';
import { useCareerStore } from '~~/common/stores/career';

export default {

  mixins: [languageDataMixin],
  data () {
    return {
      urlPrefix: URL_PREFIX,
    };
  },
  computed: {
    getSearchUrl () {
      return (searchText) => {
        const searchTextOrEmpty = searchText.length ? searchText : [''];
        const encodedSearchText = searchTextOrEmpty?.map(currentText => encodeURIComponent(currentText));
        return `${this.localeCodeToUrl}${URL_PREFIX}/search` + otqs({ q: encodedSearchText });
      };
    },
    compareProductsCategoryListUrl () {
      const categoryStore = useCategoryStore();
      const compareStore = useCompareStore();
      const productsStore = useProductsStore();
      // find category slug URL from categories $store by first compared product in list
      const itemCategoryData = findCategoryById(parseInt(compareStore.compareItems[0]?.categoryId), categoryStore.categories?.children);
      // if find data
      if (itemCategoryData) {
        // return URL: use the parent > parentNameArr
        const c = itemCategoryData.parentsNameArr[itemCategoryData.parentsNameArr.length - 2];
        return `${this.localeCodeToUrl}${URL_PREFIX}/${c?.slug}${c?.categoryId ? '.c-' + c.categoryId : ''}`;
      }
      // if not found items in compare list, then find last
      const visitedPagePath = productsStore.visitedPages.find(item => item.type === 'category')?.path;
      if (visitedPagePath) {
        return `${this.localeCodeToUrl}${visitedPagePath}`;
      }
      return false;
    },
  },
  methods: {
    productUrl (variant) {
      return getProductUrl(variant);
    },
    getCategoryUrl (category) {
      return getCategoryUrl(category);
    },
    getBoutiqueUrl (category) {
      return getBoutiqueUrl(category);
    },
    getShortlistUrl () {
      return getShortlistUrl(...arguments);
    },
  },
};

export function getProductUrl (variant) {
  return `${localeCodeToUrl.value}${URL_PREFIX}/${slugify(variant.name, { lower: true, strict: true })}.p-${variant.sku}`;
}
export function getCategoryUrl (category) {
  return `${localeCodeToUrl.value}${URL_PREFIX}/${category.parentSlug}.c-${category.id}`;
}
export function getBoutiqueUrl (category) {
  return `${localeCodeToUrl.value}${URL_PREFIX}/boutique.b-${category.boutiqueContentRef}/${category.parentSlug}.c-${category.id}`;
}
export function getShortlistUrl ({ identifier, shType, boutiqueId, categoryId }) {
  const qParams = [
    shType === 'id' ? `shType=${shType}` : '',
    categoryId ? `categoryId=${categoryId}` : '',
    boutiqueId ? `boutiqueId=${boutiqueId}` : '',
  ]
    .filter(q => q)
    .join('&')
  ;
  return `${localeCodeToUrl.value}${URL_PREFIX}/list/${identifier}${qParams.length ? '?' + qParams : ''}`;
}
export function getCareerUrl ({ career, $logger = useNuxtApp().$logger }) {
  try {
    if (!career || typeof career !== 'object') {
      return '';
    }
    const hash = ''; // 'TODOhash'
    const {
      advertisementId,
      marketingName,
      location: cLocation,
    } = career;
    return `${localeCodeToUrl.value}/karrier/allas/${slugify(cLocation)}-${slugify(marketingName)}.j-${advertisementId}${hash ? '/' + hash : ''}`;
  } catch (err) {
    $logger.error(err);
    return '';
  }
}
export function getCareersUrl ({ filterGroups, possibleFilters, $logger = useNuxtApp().$logger }) {
  try {
    if (!filterGroups || typeof filterGroups !== 'object') {
      return '';
    }
    const { createCareerAdvertisementsQuery } = useCareerStore();
    let newPageUrl = createCareerAdvertisementsQuery({
      page: null,
      itemsPerPage: null,
      filterGroups: { ...filterGroups, location: [null] },
    });
    const idx = newPageUrl.indexOf('?');
    newPageUrl = (idx === -1) ? '' : newPageUrl.slice(idx);
    const loc = slugifyPremise({ premise: possibleFilters?.location?.values?.[filterGroups?.location?.[0]]?.name });
    return `${localeCodeToUrl.value}/karrier/allasok${loc ? '/' + loc : ''}${newPageUrl.size ? '?' : ''}${newPageUrl.toString()}`;
  } catch (err) {
    $logger.error(err);
    return '';
  }
}
export function slugifyPremise ({ premise } = {}) {
  return slugify(premise || '').toLowerCase();
}
