<template>
  <section :class="$style.categorySidebar" :aria-labelledby="UUID">
    <div :class="$style.header">
      <lv-button
        ref="headerCloseBtn"
        styling="small-primary-dark-just-icon"
        :aria-label="$awt('aw.menu_sidebar.close_btn')"
        :class="$style.headerCloseBtn"
        @click="hide"
      >
        <lv-icon name="close-24" :size="16" style="vertical-align: unset;" />
      </lv-button>
      <h2 :id="UUID" class="awSrOnlyAbs" v-text="$awt('aw.menu_sidebar.tree.title')" />
      <div :class="$style.headerSubTreeTitle" style="--awMenuSidebarTransitionSizeY: 15px;position: relative;">
        <template v-if="transitionAttrs">
          <transition v-bind="transitionAttrs" :style="{ '--awMenuSidebarTransitionDir': transitionDir }">
            <h3
              v-if="virtuallyFocusedMainTreeMenuItem0 && subTreeMenu0 !== mainTreeMenu"
              v-text="virtuallyFocusedMainTreeMenuItem0.title"
            />
          </transition>
          <transition v-bind="transitionAttrs" :style="{ '--awMenuSidebarTransitionDir': transitionDir * -1 }">
            <h3
              v-if="!virtuallyFocusedMainTreeMenuItem0 && virtuallyFocusedMainTreeMenuItem1"
              v-text="virtuallyFocusedMainTreeMenuItem1.title"
            />
          </transition>
        </template>
      </div>
      <div :class="$style.headerBackBtn" style="--awMenuSidebarTransitionSizeX: 35px;position: relative;">
        <template v-if="transitionAttrs">
          <transition v-bind="transitionAttrs" :style="{ '--awMenuSidebarTransitionDir': transitionDir * -1 }">
            <nuxt-link
              :to="localePath('/')"
              :aria-label="$awt('aw.common.header.logo.label')"
              class="awFocusV awBr8"
              @click="hide"
            >
              <lv-icon
                v-if="isOnTop"
                name="auchan-logo"
                :size="32"
                fill
                :square="false"
              />
            </nuxt-link>
          </transition>
          <transition v-bind="transitionAttrs" :style="{ '--awMenuSidebarTransitionDir': transitionDir * -1 }">
            <lv-button
              v-if="!isOnTop"
              styling="small-secondary-dark-just-icon"
              :aria-label="$awt('aw.menu_sidebar.tree.go_up')"
              @click="goUp"
            >
              <lv-icon name="arrow-left-24" :size="16" style="vertical-align: unset;" />
            </lv-button>
          </transition>
        </template>
      </div>
    </div>
    <aw-svg-border is-horizontal :class="$style.svgBorder" />
    <div :class="['awScrollbar', $style.categoryLinks]">
      <div :class="$style.categoryLinksRel" style="--awMenuSidebarTransitionSizeX: 100px">
        <transition v-bind="transitionAttrs" :style="{ '--awMenuSidebarTransitionDir': transitionDir }">
          <aw-tree-menu
            v-if="virtuallyFocusedMainTreeMenuItem0"
            v-model:virtually-focused-tree-item="virtuallyFocusedMainTreeMenuItem0"
            :expanded-tree-items="expandedMainTreeMenuItems0"
            :tree="subTreeMenu0"
            is-root
            :is-ecom-page="isEcomPageFromProp"
            :aria-labelledby="UUID"
            :custom-toggle="onToggle"
            @update:expanded-tree-items="setExpandedMainTreeMenuItems(0, $event)"
            @navigated="navigated"
          />
        </transition>
        <transition v-bind="transitionAttrs" :style="{ '--awMenuSidebarTransitionDir': transitionDir * -1 }">
          <aw-tree-menu
            v-if="!virtuallyFocusedMainTreeMenuItem0 && virtuallyFocusedMainTreeMenuItem1"
            v-model:virtually-focused-tree-item="virtuallyFocusedMainTreeMenuItem1"
            :expanded-tree-items="expandedMainTreeMenuItems1"
            :tree="subTreeMenu1"
            is-root
            :is-ecom-page="isEcomPageFromProp"
            :aria-labelledby="UUID"
            :custom-toggle="onToggle"
            @update:expanded-tree-items="setExpandedMainTreeMenuItems(0, $event)"
            @navigated="navigated"
          />
        </transition>
        <div :class="$style.blockingBackdrop" />
      </div>
    </div>
    <aw-svg-border is-horizontal :class="[$style.svgBorder, $style.svgBorderBottom]" />
    <ul class="ulReset" :class="$style.specialGroupLinks">
      <li v-for="specialGroupLink in specialGroupLinks" :key="specialGroupLink.href">
        <nuxt-link
          :to="specialGroupLink.href.link"
          :target="specialGroupLink.href.isOuter ? '_blank' : null"
          :class="[$style.specialGroupLink, 'awFocusV']"
          @click="hide"
        >
          <span v-text="specialGroupLink.text" />
          <span :class="$style.boutiqueIconWrapper">
            <lv-icon v-if="specialGroupLink.iconName" :name="specialGroupLink.iconName" :size="16" />
            <span v-else-if="specialGroupLink.iconHtml" v-html="specialGroupLink.iconHtml" />
          </span>
        </nuxt-link>
      </li>
    </ul>
    <div :class="$style.footer">
      <a
        v-for="socialLink in socialLinks"
        :key="socialLink.href"
        :href="socialLink.href"
        class="awFocusV"
        :aria-label="socialLink.text"
      >
        <lv-icon :name="socialLink.iconName" :size="24" />
      </a>
      <aw-language-selector :class="$style.languageSelector" />
    </div>
  </section>
</template>

<script setup>
  import { ref, shallowRef, computed, watch, nextTick, onMounted, useCssModule } from 'vue';
  import { useNuxtApp } from 'nuxt/app';
  import { URL_PREFIX } from '~/awPlugins/app-config.js';
  import {
    BOUTIQUE_FLAG_PRODUCTS,
    BOUTIQUE_REDIRECT_URL,
    BOUTIQUE_SHORTLIST,
  } from '~~/common/config/BoutiqueConfig.js';
  import { categorySlugify } from '~~/common/utils/category.js';
  import { uuid4, base64Decode } from '~~/common/utils';
  import { PUSH_CLICK_CATEGORY } from '~~/common/plugins/aw-analytics.js';
  import { AW_AX_CACHE_SEGMENTATION } from '~~/common/plugins/axios/awAxCache.js';
  import { useUserStore } from '~~/common/stores/user.js';
  import { getCategoryUrl, getBoutiqueUrl, getShortlistUrl } from '~~/common/mixins/urlMixin.js';
  import { LvIcon, LvButton } from '~~/common/components/loginet-vue-shop/index.mjs';
  import AwSvgBorder from '~~/common/components/AwSvgBorder.vue';
  import AwTreeMenu from '~~/common/components/AwTreeMenu.vue';
  import AwLanguageSelector from '~~/common/components/Common/AwLanguageSelector.vue';

  const props = defineProps({
    show: {
      type: Boolean,
      required: true,
    },
    isEcomPage: {
      type: Boolean,
      default: false,
    },
  });

  const emits = defineEmits(['update:show']);

  const $style = useCssModule();

  const {
    $awt,
    $urlParts,
    $awAnalytics,
    $api,
    $logger,
    $i18n,
    $awMergedConfig: { isShop },
  } = useNuxtApp();

  const ROOT_CAT_ID = 'aw_c';
  const UUID = `aw_${uuid4()}`;
  const mainTreeMenu = ref([]);
  const subTreeMenu0 = ref([]);
  const subTreeMenu1 = ref([]);
  const expandedMainTreeMenuItems0 = shallowRef(new Set());
  const expandedMainTreeMenuItems1 = shallowRef(new Set());
  const virtuallyFocusedMainTreeMenuItem0 = shallowRef(null);
  const virtuallyFocusedMainTreeMenuItem1 = shallowRef(null);
  const specialGroupLinkBoutiques = shallowRef([]);
  const transitionDir = ref(1);
  const isAnimationAllowed = ref(false);
  const isDataInitialized = ref(false);
  const headerCloseBtn = ref(null);

  const transitionAttrs = computed(() => (isAnimationAllowed.value
    ? {
      'enter-active-class': $style.transitionEnterActive,
      'enter-from-class': $style.transitionEnterFrom,
      'leave-active-class': $style.transitionLeaveActive,
      'leave-to-class': $style.transitionLeaveTo,
      mode: 'out-in',
    }
    : null
  ));

  const isOnTop = computed(() => {
    const tree = (virtuallyFocusedMainTreeMenuItem0.value && subTreeMenu0.value) || (virtuallyFocusedMainTreeMenuItem1.value && subTreeMenu1.value);
    return tree === mainTreeMenu.value;
  });

  const specialGroupLinks = computed(() => {
    return [...function * () {
      if (isShop && useUserStore().isEcomUser) {
        yield {
          iconName: 'heart-16',
          href: {
            link: `${URL_PREFIX}/profile/saved-baskets`,
            isOuter: false,
          },
          text: $awt('aw.profile.menu.saved_baskets'),
        };
      }
      for (const boutique of specialGroupLinkBoutiques.value) {
        const boutiqueData = {
          iconHtml: boutique.icon,
          text: boutique.name,
          href: null,
        };
        switch (boutique.boutiqueContentType) {
        case BOUTIQUE_FLAG_PRODUCTS: {
          boutiqueData.href = {
            link: getBoutiqueUrl(boutique),
            isOuter: false,
          };
          break;
        }
        case BOUTIQUE_SHORTLIST: {
          boutiqueData.href = {
            link: getShortlistUrl({
              identifier: boutique.boutiqueContentRef,
              shType: 'id',
              boutiqueId: boutique.id,
              categoryId: boutique.id,
            }),
            isOuter: false,
          };
          break;
        }
        case BOUTIQUE_REDIRECT_URL: {
          boutiqueData.href = $urlParts(boutique.boutiqueContentRef);
          break;
        }
        }
        yield boutiqueData;
      }
    }()];
  });

  const socialLinks = computed(() => [
    {
      iconName: 'ic-baseline-facebook-24',
      href: 'aw.common.social.facebook_url',
      text: 'aw.common.social.facebook',
    },
    {
      iconName: 'mdi-instagram-24',
      href: 'aw.common.social.instagram_url',
      text: 'aw.common.social.instagram',
    },
    {
      iconName: 'mdi-youtube-24',
      href: 'aw.common.social.youtube_url',
      text: 'aw.common.social.youtube',
    },
  ].map((link) => {
    link.href = $awt(link.href);
    link.text = $awt(link.text);
    return link;
  }));

  const isEcomPageFromProp = computed(() => {
    return props.isEcomPage;
  });

  watch($i18n.locale, (newVal, oldVal) => {
    if (newVal !== oldVal) {
      hide();
    }
  });

  onMounted(fetchInitialData);

  async function fetchInitialData () {
    isDataInitialized.value = false;
    const shopRoot = {
      uuid: ROOT_CAT_ID,
      title: $awt('aw.menu_sidebar.tree.root_category'),
      isEcomHomePage: true,
      link: {
        link: isShop ? '/shop' : '/',
        isOuter: false,
      },
      newTab: false,
      children: [],
      isDir: true,
      parentItem: null,
      prevItem: null,
      nextItem: null,
    };
    const [mainMenu, categoryTree] = await Promise.all([
      ($api.$get('/content_menus/main-menu')
        .then((response) => {
          const items = response?.children?.length ? response.children : [];
          return transformContentMenus(items, null);
        })
        .catch((err) => {
          $logger.error(err);
          return [];
        })
      ),
      fetchCategoryData({
        categoryId: 0,
        parentItem: shopRoot,
        firstTwoBoutieques: true,
      }).then((response) => {
        shopRoot.children = response;
        return [shopRoot];
      }),
    ]);
    if (mainMenu.length) {
      categoryTree[categoryTree.length - 1].nextItem = mainMenu[0];
      mainMenu[0].prevItem = categoryTree[categoryTree.length - 1];
    }
    mainTreeMenu.value = [
      ...categoryTree,
      ...mainMenu,
    ];
    subTreeMenu0.value = mainTreeMenu.value;
    subTreeMenu1.value = mainTreeMenu.value;
    virtuallyFocusedMainTreeMenuItem0.value = mainTreeMenu.value[0];
    isDataInitialized.value = true;
    nextTick(() => {
      isAnimationAllowed.value = true;
    });
  }

  function fetchCategoryData ({
    categoryId,
    parentItem = null,
    firstTwoBoutieques = false,
  }) {
    return ($api.$get(`/tree/${categoryId}?depth=1`, { awAxCache: AW_AX_CACHE_SEGMENTATION })
      .then((response) => {
        categorySlugify(response);
        if (firstTwoBoutieques && response?.children?.length) {
          specialGroupLinkBoutiques.value = (response.children
            .filter(c => c.boutique)
            .slice(0, 2)
            .map((b) => {
              b.icon = b.icon ? base64Decode(b.icon) : undefined;
              return b;
            })
          );
        }
        const items = (
          filterCategory(
            response?.children?.length ? response.children : [],
          )
        );
        return transformCategories(items, parentItem);
      })
      .catch((err) => {
        $logger.error(err);
        return [];
      })
    );
  }

  function filterCategory (items) {
    const arr = items.filter(cat => !cat.boutique && cat.productCount);
    arr.forEach((cat) => {
      if (cat.children?.length) {
        cat.children = filterCategory(cat.children);
      }
    });
    return arr;
  }

  function transformContentMenus (items, parentItem = null) {
    for (let idx = 0; idx < items.length; idx++) {
      const origItem = items[idx];
      const prevItem = items[idx - 1] || null;
      items[idx] = {
        uuid: `${parentItem?.uuid ? parentItem.uuid + '/' : ''}aw_menu_${origItem.id}`,
        title: origItem.title || '',
        link: origItem.link ? $urlParts(origItem.link) : {
          link: '',
          isOuter: false,
        },
        newTab: origItem.newTab || false,
        children: origItem.children || [],
        isDir: Boolean(origItem.children?.length),
        parentItem,
        prevItem,
        nextItem: null,
      };
      if (prevItem) {
        prevItem.nextItem = items[idx];
      }
      transformContentMenus(items[idx].children, items[idx]);
    }
    return items;
  }

  function transformCategories (items, parentItem = null) {
    for (let idx = 0; idx < items.length; idx++) {
      const origItem = items[idx];
      const prevItem = items[idx - 1] || null;
      origItem.parentSlug = (parentItem?.parentSlug ? `${parentItem?.parentSlug}/` : '') + (origItem.parentSlug ?? '');
      items[idx] = {
        uuid: `${parentItem?.uuid ? parentItem.uuid + '/' : ''}aw_cat_${origItem.id}`,
        title: origItem.name || '',
        link: {
          isOuter: false,
          link: getCategoryUrl(origItem) || '',
        },
        categoryId: origItem.id,
        newTab: false,
        parentSlug: origItem.parentSlug,
        children: origItem.children || [],
        isDir: Boolean(origItem.childCount),
        parentItem,
        prevItem,
        nextItem: null,
      };
      if (prevItem) {
        prevItem.nextItem = items[idx];
      }
      transformCategories(items[idx].children, items[idx]);
    }
    return items;
  }

  async function setExpandedMainTreeMenuItems (key, newVal) {
    const expandedMainTreeMenuItems = key ? expandedMainTreeMenuItems1 : expandedMainTreeMenuItems0;
    const oldVal = expandedMainTreeMenuItems.value;
    const newUuids = [...newVal].filter(uuid => !oldVal.has(uuid));
    (await Promise.all(newUuids
      .map(async (newUuid) => {
        const categoryId = newUuid.match(/aw_cat_(\d+)$/)?.[1];
        if (categoryId) {
          const cat = newUuid.split('/').reduce(
            (acc, level) => {
              return acc.children.find(item => item.uuid === (acc.uuid ? acc.uuid + '/' : '') + level);
            },
            { children: mainTreeMenu.value },
          );
          if (cat && !cat.children.length) {
            cat.children = await fetchCategoryData({
              categoryId,
              parentItem: cat,
            });
          }
        }
      }))
    );
    expandedMainTreeMenuItems.value = newVal;
  }

  let lastMode = 'toggle';

  function goUp () {
    const parentItem = (virtuallyFocusedMainTreeMenuItem0.value || virtuallyFocusedMainTreeMenuItem1.value).parentItem;
    onToggle(parentItem, { newMode: 'goUp' });
  }

  async function onToggle (item, { newMode = 'toggle' } = { newMode: 'toggle' }) {
    transitionDir.value *= -1;
    if (lastMode !== newMode) {
      transitionDir.value *= -1;
      lastMode = newMode;
    }
    if (virtuallyFocusedMainTreeMenuItem0.value) {
      await setExpandedMainTreeMenuItems(1, new Set(item ? [item.uuid] : []));
      subTreeMenu1.value = item?.children || mainTreeMenu.value;
      virtuallyFocusedMainTreeMenuItem1.value = item || mainTreeMenu.value[0];
      virtuallyFocusedMainTreeMenuItem0.value = null;
    } else {
      await setExpandedMainTreeMenuItems(0, new Set(item ? [item.uuid] : []));
      subTreeMenu0.value = item?.children || mainTreeMenu.value;
      virtuallyFocusedMainTreeMenuItem1.value = null;
      virtuallyFocusedMainTreeMenuItem0.value = item || mainTreeMenu.value[0];
    }
  }

  function navigated (item) {
    try {
      if (item.uuid.startsWith(`${ROOT_CAT_ID}/`)) {
        const parentsNameArr = [];
        let iter = item;
        while (iter.parentItem) {
          parentsNameArr.unshift(iter.title);
          iter = iter.parentItem;
        }
        $awAnalytics[PUSH_CLICK_CATEGORY]({ parentsNameArr });
      }
    } catch (err) {
      $logger.error(err);
    }
    hide();
  }

  function hide () {
    emits('update:show', false);
  }

  function openOnlineStoreMenu () {
    const interVal = setInterval(() => {
      if (isDataInitialized.value) {
        clearInterval(interVal);
        onToggle(mainTreeMenu.value[0], { newMode: 'toggle' });
      }
    }, 50);
  }

  function openCategoryMenu (categoryIds) {
    const interVal = setInterval(async () => {
      if (isDataInitialized.value) {
        clearInterval(interVal);
        if (categoryIds.length === 0) {
          await onToggle(mainTreeMenu.value[0], { newMode: 'toggle' });
        } else {
          let key = 1;
          let lastMainTreeMenuElement = mainTreeMenu.value[0];
          for (let i = 0; i < categoryIds.length; i++) {
            const categoryId = categoryIds[i];
            const cat = lastMainTreeMenuElement.children.find(item => item.categoryId === categoryId);
            if (cat) {
              await setExpandedMainTreeMenuItems(key, new Set([cat.uuid]));
              key = key === 1 ? 0 : 1;
              lastMainTreeMenuElement = cat;
            }
          }
          await onToggle(lastMainTreeMenuElement, { newMode: 'toggle' });
        }
      }
    }, 50);
  }

  function focusOnTop () {
    headerCloseBtn.value?.$el?.focus();
  }

  defineExpose({
    openOnlineStoreMenu,
    openCategoryMenu,
    focusOnTop,
  });
</script>

<style module lang="scss" rel="stylesheet/scss">
.categorySidebar {
  position: fixed;
  z-index: 11;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  background: $color-white;

  @include tablet(min) {
    width: 436px;
  }
}

.footer {
  padding: 0 16px;

  @include tablet(min) {
    padding: 0 40px;
  }
}

.header,
.svgBorder,
.specialGroupLinks {
  margin: 0 16px;

  @include tablet(min) {
    margin: 0 40px;
  }
}

.svgBorder {
  width: unset;
}

.svgBorderBottom {
  margin-top: auto;
}

.header {
  display: grid;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  grid-template-columns: 32px 1fr 32px;
  grid-template-areas: "backBtn subTreeTitle closeBtn";

  @include tablet(min) {
    padding-top: 40px;
  }
}

.footer {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 20px 16px 0;
  padding-bottom: 20px;
  color: $color-white;
  background: $color-strawberry-500;

  @include tablet(min) {
    padding-right: 40px;
    padding-left: 40px;
  }
}

.headerCloseBtn {
  grid-area: closeBtn;
}

.headerSubTreeTitle {
  font-size: 12px;
  font-weight: 900;
  line-height: 20px;
  text-transform: uppercase;
  grid-area: subTreeTitle;
  translate: 0 5px;
}

.headerBackBtn {
  grid-area: backBtn;
}

.categoryLinksRel {
  position: relative;
  flex-grow: 1;
}

.categoryLinks {
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  flex-grow: 1;
  margin: 0 2px 0 16px;
  padding: 20px 0 0;

  padding-right: 14px;

  @include tablet(min) {
    margin: 0 26px 0 40px;
  }
}

.specialGroupLinks {
  padding: 8px 0;
}

.specialGroupLink {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  display: inline-flex;
  align-items: center;
  flex-direction: row-reverse;
  padding: 12px;
  gap: 8px;

  @include tablet(min) {
    font-size: 14px;
    line-height: 20px;
  }
}

.languageSelector {
  margin-left: auto;
}

.boutiqueIconWrapper {
  width: 16px;
  height: 16px;
}

.transition {
  &EnterActive,
  &LeaveActive,
  &EnterFrom,
  &LeaveTo {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    transition: all 0.6s cubic-bezier(0.47, 0.41, 0.68, 1.25);

    + .blockingBackdrop {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &EnterActive,
  &LeaveActive {
    opacity: 1;
  }

  &EnterFrom,
  &LeaveTo {
    opacity: 0;
    translate: calc(var(--awMenuSidebarTransitionDir, 1) * var(--awMenuSidebarTransitionSizeX, 0px)) calc(var(--awMenuSidebarTransitionDir, 1) * var(--awMenuSidebarTransitionSizeY, 0px));
  }
}
</style>
