<template>
  <div
    :class="$style.container"
    :style="{
      '--aspectRatio': imageAspectRatio,
    }"
  >
    <div
      :class="$style.carousel"
      @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave"
    >
      <div :class="$style.carouselAspectRatioPadding" />
      <aw-buttons
        v-for="(image, index) in images"
        :key="index"
        :class="[
          $style.carouselImage,
          {
            [$style.carouselImageActive]: activeIndex === index,
            [$style.carouselImagePrev]: previousIndex === index,
          }
        ]"
        v-bind="{
          isBtnInteractive (button) {
            return true;
          },
          content: {
            ...content.components[index],
            button: {
              items: [
                {
                  link: content.components[index].link,
                },
              ],
            },
          },
          limit: 1,
          showTitle: false,
          analyticsBannerInteraction: analyticsBannerInteractionForBanner,
          analyticsMeasurable,
          containerAttrs: {
            style: {
              height: '100%',
            },
          },
          buttonAttrs: {
            styling: 'reset',
            stretch: true,
            style: {
              display: 'flex',
              'flex-direction': 'column',
              height: '100%',
            },
          },
        }"
      >
        <template #[buttonTemplate[index]]>
          <img
            :src="$addWebpPostfixIfSupported({ url: image?.url, webpUrl: `${image?.url}.webp` })"
            :alt="image?.alt"
            :loading="!isLazyImage && index === 0 ? 'eager': 'lazy'"
            :class="$style.carouselImageTag"
            style="height: 0;"
          >
        </template>
      </aw-buttons>
    </div>
    <aw-carousel-v3-buttons
      v-if="images.length > 1"
      :is-paused="paused"
      :class="$style.carouselNavigation"
      aria-label-prev="aw.cms.carousel_v3.image_only.prev"
      aria-label-toggle="aw.cms.carousel_v3.image_only.toggle"
      aria-label-next="aw.cms.carousel_v3.image_only.next"
      @prev="prev"
      @toggle-pause="togglePause"
      @next="next"
    />
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import analyticsBannerInteractionMixin from '~~/common/mixins/analyticsBannerInteractionMixin.js';
  import {
    createAwCarouselV3,
    createAwCarouselV3BannerAnalytics,
  } from '~~/common/mixins/awCarouselV3Mixin.js';
  import { useRuntimeConfig } from 'nuxt/app';

  export default {
    name: 'AwCarouselV3ImageOnly',
    components: {
      AwButtons: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwButtons')),
      AwCarouselV3Buttons: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwCarouselV3/AwCarouselV3Buttons.vue')),
    },
    mixins: [analyticsBannerInteractionMixin],
    inject: ['getRowIndexById'],
    props: {
      content: {
        type: Object,
        required: true,
      },
    },
    setup (props) {
      const {
        paused,
        activeIndex,
        previousIndex,
        contents,
        next,
        prev,
        togglePause,
        handleMouseEnter,
        handleMouseLeave,
      } = createAwCarouselV3({
        content: computed(() => props.content),
      });
      const { analyticsBannerInteraction } = createAwCarouselV3BannerAnalytics({
        contents,
        activeIndex,
        analyticsMeasurable: computed(() => props.analyticsMeasurable),
        analyticsBannerInteraction: computed(() => props.analyticsBannerInteraction),
      });
      return {
        paused,
        activeIndex,
        previousIndex,
        next,
        prev,
        togglePause,
        handleMouseEnter,
        handleMouseLeave,
        analyticsBannerInteractionForBanner: analyticsBannerInteraction,
        imageAspectRatio: 'calc(9 / 32)',
      };
    },
    computed: {
      images () {
        return this.content.components.map((component) => {
          return component?.media?.contentImage;
        });
      },
      buttonTemplate () {
        return this.content.components.map((component) => {
          return (component?.button?.items?.length || component.link) ? 'buttonText_0' : 'content';
        });
      },
      isLazyImage () {
        const limit = useRuntimeConfig().public.lazyLoadedComponentsLimit;
        if (!this.getRowIndexById) {
          return true;
        }
        return this.getRowIndexById(this.content.id) >= limit;
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.container {
  position: relative;
}

.carousel {
  position: relative;

  &Image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    transition: opacity 1.2s, visibility 1.2s, transform 1.2s;
    opacity: 0;

    @include tablet(min) {
      border-radius: 24px;
    }

    &Active {
      visibility: visible;
      opacity: 1;
    }

    &Prev:not(.carouselImageActive) {
      transform: scale(0.85);
    }

    &Tag {
      height: 100%!important;
      border-radius: 16px;
      object-fit: cover;
      object-position: center;
    }
  }

  &Navigation {
    display: grid;
    width: 128px;
    margin: 20px auto;
    padding: 8px;
    border: 1px solid $color-border;
    border-radius: 12px;
    background: $color-background-4;
    grid-template-columns: repeat(3, 32px);
    gap: 8px;

    @include tablet(min) {
      margin: 20px auto 40px;
    }
    @include desktop-medium(min) {
      margin: 40px auto;
    }
  }
}

.carouselAspectRatioPadding {
  padding-top: calc(100% * var(--aspectRatio));
}
</style>
