<template>
  <div :class="$style.video" :style="{ paddingBottom }">
    <iframe :src="`https://www.youtube-nocookie.com/embed/${youtubeId}`" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
  </div>
</template>

<script>
  export default {
    name: 'LvYoutubeVideo',
    props: {
      youtubeId: {
        type: String,
        required: true,
      },
      paddingBottom: {
        type: String,
        default: undefined,
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
$video-player-ratio: percentage(math.div(9, 16)) !default;

.video {
  position: relative;
  padding-bottom: $video-player-ratio;

  iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }

  & + & {
    @include md() {
      margin-top: 20px;
    }

    margin-top: 10px;
  }
}
</style>
