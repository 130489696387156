<template>
  <div ref="eventWrapperElement">
    <lv-cms
      v-if="content.contentText"
      :content="content.contentText"
      :class="$style.contentText"
      version2
      data-content-text
    />
  </div>
</template>

<script>
  import { nextTick } from 'vue';
  import { LvCms } from '~~/common/components/loginet-vue-shop/index.mjs';
  import analyticsBannerInteractionMixin from '~~/common/mixins/analyticsBannerInteractionMixin.js';

  const events = ['click', 'auxclick', 'contextmenu'];

  export default {
    name: 'AwTextBlock',
    components: {
      LvCms,
    },
    mixins: [analyticsBannerInteractionMixin],
    props: {
      content: {
        type: Object,
        required: true,
      },
    },
    emits: ['mounted'],
    mounted () {
      nextTick(() => {
        if (this.$refs?.eventWrapperElement) {
          events.forEach((e) => {
            this.$refs.eventWrapperElement.removeEventListener(e, this.clickHandler);
            this.$refs.eventWrapperElement.addEventListener(e, this.clickHandler);
          });
        }
      });
      this.$emit('mounted');
    },
    beforeUnmount () {
      if (this.$refs?.eventWrapperElement) {
        events.forEach((e) => {
          this.$refs.eventWrapperElement.removeEventListener(e, this.clickHandler);
        });
      }
    },
    methods: {
      clickHandler (evt) {
        if (evt.target instanceof HTMLAnchorElement || evt.target instanceof HTMLButtonElement) {
          this.sendBannerInteractionAnalytics();
        }
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.contentText {
  word-break: break-word;
  hyphens: auto;

  h2 {
    strong {
      font-size: 22px;
      font-weight: $font-weight-bold-v2;
      line-height: 28px;
      margin-right: 16px !important;
      margin-left: 16px !important;

      @include tablet(min) {
        font-size: 26px;
        line-height: 32px;
      }
    }
  }
}
</style>
