<template>
  <div
    role="button"
    tabindex="0"
    :class="[$style.tab, {[$style.tabSelected]: selected}]"
    @click="selectTab"
    @keydown.space="selectTab"
    @keydown.enter="selectTab"
  >
    <div :class="$style.animation">
      <div :class="$style.animationTop">
        <img
          :src="tab.icon"
          :class="[$style.animationTopIcon]"
          :alt="tab.iconAlt ? tab.iconAlt : null"
          loading="lazy"
        >
        <div :class="[$style.animationTopDisc, $style.animationTransformMatrix]" />
      </div>
      <div :class="[$style.animationBase, $style.animationTransformMatrix]" />
    </div>
    <h3 :class="$style.title">
      <span :class="[$style.title, $style.titleUp]">
        {{ tab.name }}
      </span>
      <span aria-hidden="true" :class="[$style.title, $style.titleDown]">
        {{ tab.name }}
      </span>
    </h3>
  </div>
</template>

<script>
  export default {
    name: 'AwRedTab',
    props: {
      tab: {
        type: Object,
        required: true,
      },
      selected: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['select'],
    setup (props, { emit }) {
      const selectTab = () => {
        emit('select', props.tab.contentId);
      };

      return {
        selectTab,
      };
    },
  };

</script>

<style module lang="scss" rel="stylesheet/scss">
.tab {
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 16px 8px;
  cursor: pointer;
  transition: all .3s ease-in-out;
  word-break: break-word;
  hyphens: auto;
  border: 1px solid $color-white;
  border-radius: 16px;
  background-color: $color-white;
  box-shadow: 0 2px 8px 0 rgba($color-olive-750, 0.12);
  @include tablet(min) {
    padding: 16px;
    border-radius: 24px;
    box-shadow: 0 8px 24px 0 rgba($color-olive-750, 0.12);
  }

  .title {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 48px;
    margin-bottom: 24px;

    &Up, &Down {
      font-family: $secondary-font;
      font-size: 12px;
      font-weight: $font-weight-bold-v2;
      font-style: normal;
      line-height: 16px;
      text-align: center;
      color: $color-text-primary;
      @include tablet(min) {
        font-size: 14px;
        line-height: 20px;
      }
      @include desktop-small(min) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &Up {
      position: absolute;
      top: 0;
      transition: all .3s ease-in-out;
      opacity: 1;
    }

    &Down {
      position: absolute;
      top: 24px;
      transition: all .3s ease-in-out;
      opacity: 0;
    }
  }

  .animation {
    position: relative;
    width: 65px;
    height: 65px;
    margin-bottom: 16px;
    @include desktop-small(min) {
      width: 126px;
      height: 108px;
      margin-bottom: 0;
    }

    &TransformMatrix {
      transform: matrix(0.866044, 0.499967, -0.866044, 0.499967, 0, -7.28796);
    }

    &Top {
      &Disc {
        position: absolute;
        z-index: 1;
        bottom: 27px;
        left: 17px;
        width: 33px;
        height: 33px;
        transition: all $animation-speed-fast $animation-timing-function;
        border-radius: 50%;
        background-color: $color-strawberry-500;
        @include desktop-small(min) {
          bottom: 40px;
          left: 38px;
          width: 50px;
          height: 50px;
        }
      }

      &Icon {
        position: absolute;
        z-index: 2;
        bottom: 37px;
        left: 18px;
        width: 34px;
        height: 34px;
        transition: all $animation-speed-fast $animation-timing-function;
        filter: drop-shadow(0 11px 5px rgba($color-black, 0.7));
        @include desktop-small(min) {
          bottom: 51px;
          left: 37px;
          width: 52px;
          height: 52px;
        }
      }
    }

    &Base {
      position: absolute;
      z-index: 0;
      bottom: 0;
      left: 10px;
      width: 46px;
      height: 46px;
      transition: all $animation-speed-fast $animation-timing-function;
      border: 1px dashed rgba($color-strawberry-500, 0.32);
      border-radius: 50%;
      background-color: rgba($color-strawberry-500, 0.08);
      box-shadow: inset 0 0 0 10px white;
      @include desktop-small(min) {
        left: 30px;
        width: 68px;
        height: 68px;
        box-shadow: inset 0 0 0 18px white;
      }
    }
  }

  &:hover {
    border: 1px solid $color-strawberry-500;
    box-shadow: inset 0 0 0 4px rgba($color-strawberry-500, 0.12);

    .title {
      &Up {
        top: -24px;
        opacity: 0;
      }

      &Down {
        top: 0;
        opacity: 1;
      }
    }

    .animation {
      &Top {
        &Disc {
          bottom: 20px;
        }

        &Icon {
          bottom: 30px;
          @include desktop-small(min) {
            bottom: 30px;
          }
        }
      }

      &Base {
        box-shadow: inset 0 0 0 5px white;
      }
    }
  }

  &Selected, &Selected:hover {
    border: 1px solid $color-strawberry-500;
    box-shadow: inset 0 0 0 6px rgba($color-strawberry-500, 0.12);
    @include tablet(min) {
      box-shadow: inset 0 0 0 8px rgba($color-strawberry-500, 0.12);
    }

    .title {
      &Up {
        top: 0;
        opacity: 1;
      }

      &Down {
        top: 24px;
        opacity: 0;
      }
    }

    .animation {
      &Top {
        &Disc {
          bottom: 8px;
        }

        &Icon {
          bottom: 17px;
          filter: drop-shadow(0 11px 5px rgba($color-black, 0))
        }
      }

      &Base {
        box-shadow: inset 0 0 0 100px white;
      }
    }
  }
}
</style>
