<template>
  <fieldset v-if="availableLocales.length > 1" :class="$style.wrapper" :style="`width: ${availableLocales.length === 2 ? 'max-content' : '107px'}`">
    <legend class="awSrOnlyAbs">
      {{ $awt('aw.common.header.language_selector.title') }}
    </legend>
    <label
      v-for="locale in availableLocales"
      :key="locale.upperCaseText"
      :class="['awHiddenInputFullSizeParent', $style.item, {[$style.itemSelected]: currentLocale === locale.lowerCaseText}]"
    >
      <input
        :key="locale.upperCaseText"
        :aria-labelledby="`awLanguageSelectorLabel_${locale.upperCaseText}`"
        type="radio"
        name="awLanguageSelectorName"
        :value="locale.lowerCaseText"
        :checked="(currentLocale === locale.lowerCaseText) || null"
        class="awHiddenInputFullSizeInput"
        @click="changeLanguage(locale)"
      >
      <span v-show="false" :id="`awLanguageSelectorLabel_${locale.upperCaseText}`" v-text="$awt('aw.common.header.language_selector.language_label', { language: $awt(`aw.common.header.locales.mobile.${locale.lowerCaseText}`)})" />
      <span aria-hidden="true" v-text="locale.upperCaseText" />
    </label>
  </fieldset>
</template>

<script>
  import languageDataMixin from '~~/common/mixins/languageDataMixin';

  export default {
    name: 'AwLanguageSelector',
    mixins: [languageDataMixin],
    data () {
      return {
        active: false,
        componentKey: 0,
      };
    },
    validations: {},
    computed: {
      currentLocale () {
        return this.$i18n.locale;
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.wrapper {
  font-size: 12px;
  font-weight: $font-weight-medium-v2;
  line-height: 16px;
  padding: 2px;
  color: $color-white;
  border: 1px solid change-color($color-white, $alpha: 0.4);
  border-radius: 8px;
  background: $color-brand-primary;

  .item {
    display: inline-block;
    width: 33px;
    padding: 5px 8px;
    cursor: pointer;
    border-radius: 6px;

    &Selected {
      color: $color-brand-primary;
      background: $color-white;
    }

    > input:focus-visible + span + span {
      outline: 2px solid $color-black;
    }
  }
}
</style>
