import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';
import { useNuxtApp } from 'nuxt/app';
import { createThrottledSendBannerViewInteraction } from '~~/common/mixins/analyticsBannerInteractionMixin.js';
import { AnalyticsBannerInteraction, EMPTY_ANALYTICS_BANNER_INTERACTION } from '~~/common/utils/AnalyticsBannerInteraction';

export function createAwCarouselV3 ({ content }) {
  let time = null;
  let interval = null;
  const duration = 3500;
  let hoverCarouselElement = false;
  const paused = ref(false);
  const activeIndex = ref(0);
  const contents = computed(() => {
    return content.value.components;
  });
  const previousIndex = computed(() => {
    return (activeIndex.value === 0
      ? contents.value.length - 1
      : activeIndex.value - 1
    );
  });
  onMounted(() => {
    time = duration;
    startInterval();
  });
  onBeforeUnmount(() => {
    clearInterval(interval);
  });

  return {
    paused,
    activeIndex,
    previousIndex,
    contents,
    next,
    prev,
    togglePause,
    handleMouseEnter,
    handleMouseLeave,
    onImageError,
    getContentImage,
    calculateBackgroundImage,
  };
  function startInterval () {
    const precision = 100;
    interval = setInterval(() => {
      if (!hoverCarouselElement && !paused.value) {
        time -= precision;
      }
      if (time <= 0) {
        next();
      }
    }, precision);
  }
  function goToIndex (index) {
    activeIndex.value = index;
    time = duration;
  }
  function next () {
    let nextIndex = activeIndex.value + 1;

    // Go to the first image if the active
    // image ist the last one.
    if (!contents.value[nextIndex]) {
      nextIndex = 0;
    }

    goToIndex(nextIndex);
  }
  function prev () {
    let nextIndex = activeIndex.value - 1;

    // Go to the last image if the active
    // image is the first one.
    if (!contents.value[nextIndex]) {
      nextIndex = contents.value.length - 1;
    }

    goToIndex(nextIndex);
  }
  function togglePause () {
    paused.value = !paused.value;
  }
  function handleMouseEnter () {
    hoverCarouselElement = true;
  }
  function handleMouseLeave () {
    hoverCarouselElement = false;
  }
  function onImageError (event, content) {
    if (event.target.src.endsWith('.webp')) {
      content.isWebpMissing = true;
    } else {
      content.isImageMissing = true;
    }
  }
  function getContentImage (content) {
    const { $addWebpPostfixIfSupported } = useNuxtApp();
    return {
      url: (content.isWebpMissing
        ? content?.media?.contentImage?.url
        : $addWebpPostfixIfSupported({
          url: content?.media?.contentImage?.url,
          webpUrl: `${content?.media?.contentImage?.url}.webp`,
        })
      ),
      alt: content?.media?.contentImage?.alt,
    };
  }
  function calculateBackgroundImage (content) {
    return (
      'linear-gradient(to bottom, rgba(0,0,0,.32), rgba(0,0,0,.32)), ' +
      `url('${getContentImage(content).url}')`
    );
  }
}

export function createAwCarouselV3BannerAnalytics ({
  contents,
  activeIndex,
  analyticsMeasurable,
  analyticsBannerInteraction,
}) {
  const campaignName = computed(() => {
    return contents.value?.[activeIndex.value]?.campaignName || null;
  });
  const extendedInteraction = computed(() => {
    if (analyticsBannerInteraction.value === EMPTY_ANALYTICS_BANNER_INTERACTION) {
      return EMPTY_ANALYTICS_BANNER_INTERACTION;
    } else {
      return AnalyticsBannerInteraction.create({
        ...analyticsBannerInteraction.value,
        promotionName: campaignName.value || null,
        promotionBannerIndex: activeIndex.value + 1,
      });
    }
  });
  onMounted(() => {
    const throttledSendBannerViewInteraction = createThrottledSendBannerViewInteraction();
    watch(
      activeIndex,
      () => {
        throttledSendBannerViewInteraction({
          analyticsMeasurable: analyticsMeasurable.value,
          analyticsBannerInteraction: extendedInteraction.value,
        });
      },
      {
        immediate: true,
      },
    );
  });
  return {
    analyticsBannerInteraction: extendedInteraction,
  };
}
